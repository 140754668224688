import React from 'react'
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@material-ui/icons'
import { CancelOutlined as CancelOutlinedIcon } from '@material-ui/icons';
import { getLanguageCaptions, publicTranslations } from '../../utils/publicTranslations';


export const passwordValidationMessages = (t) => {
    if (t) {
        const baseTexts = publicTranslations.en
        return {
            passwordRequirements: t('profile:'.concat(baseTexts.passwordRequirements)),
            strLowercase: t('profile:'.concat(baseTexts.strLowercase)),
            strUppercase: t('profile:'.concat(baseTexts.strUppercase)),
            strDigit: t('profile:'.concat(baseTexts.strDigit)),
            strSpecialCharacter: t('profile:'.concat(baseTexts.strSpecialCharacter)),
            strMinLength: t('profile:'.concat(baseTexts.strMinLength)),
            strPasswordMatch: t('profile:'.concat(baseTexts.strPasswordMatch)),
            errorPasswordValidation: t('profile:'.concat(baseTexts.errorPasswordValidation)),
        }
    } else {
        const captions = getLanguageCaptions()

        return {
            passwordRequirements: captions.passwordRequirements,
            strLowercase: captions.strLowercase,
            strUppercase: captions.strUppercase,
            strDigit: captions.strDigit,
            strSpecialCharacter: captions.strSpecialCharacter,
            strMinLength: captions.strMinLength,
            strPasswordMatch: captions.strPasswordMatch,
            errorPasswordValidation: captions.errorPasswordValidation,
        }
    }
}


export const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{8,}$/;
    return passwordRegex.test(password);
}

export const getNewPasswordValidationMessage = (password, validationMessages) => {
    const reqs = [
        { label: validationMessages.strMinLength, isValid: password.newPassword.length >= 8 },
        { label: validationMessages.strUppercase, isValid: /[A-Z]/.test(password.newPassword) },
        { label: validationMessages.strLowercase, isValid: /[a-z]/.test(password.newPassword) },
        { label: validationMessages.strDigit, isValid: /\d/.test(password.newPassword) },
        { label: validationMessages.strSpecialCharacter, isValid: /[^a-zA-Z\d]/.test(password.newPassword)},
        { label: validationMessages.strPasswordMatch, isValid: (password.repeatPassword.length > 0 && password.repeatPassword === password.newPassword) }
    ]

    return (
        <>
            <p style={{ fontWeight: "bold" }}>{validationMessages.passwordRequirements + ":"}</p>
            <span style={{ whiteSpace: 'pre-line' }}>
                {reqs.map((req, index) => (
                    <div key={index} style={{ color: req.isValid ? 'green' : 'red' }}>
                        {req.isValid ?
                            <CheckCircleOutlineIcon style={{ color: 'green' }} />
                        :
                            <CancelOutlinedIcon style={{ color: 'red' }} />
                        }
                        {' ' + req.label + '.'}
                    </div>
                ))}
            </span>
        </>
    )
}
