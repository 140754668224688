import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import {
    TableFieldDescription,
    DataMapper,
} from  '../../../components/BPMTable/TableMapperCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query AllGlAccounts(
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,
        $isActive:Boolean,
        $name:String,
        $manager:String,
        $orderField:String,
    ) {
        allGlAccounts(
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            isActive:$isActive,
            name:$name,
            manager:$manager,
            orderField:$orderField,
        ) {
            count
            edges {
                node {
                    name 
                    id
                    isActive
                    manager {
                        id
                        enterpriseId
                    }
                    parent {
                        id
                        displayName
                    }
                }
            }
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const unitQuery = `
    glAccount(id: $id) {
        name
        id
        isActive
        manager {
            id
            enterpriseId
        }
        parent {
            id
            displayName
        }
    }
`

const queries = {tableQuery, unitQuery}

class InventoryGlAccountMinimalTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InventoryGlAccountMinimalTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries)
        tableCreator.setAdditionalFilters(this.filters)
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'name',
                new DataMapper("name"),
                new f.FilterDescription(new f.TextFilter("name")),
                {label: 'Name'}
            ),
            new TableFieldDescription(
                'parent',
                new DataMapper("parent.displayName"),
                new f.FilterDescription(new f.TextFilter("parent")),
                {label: 'Parent'}
            ),
            new TableFieldDescription(
                'manager',
                new DataMapper("manager.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("manager")),
                {label: 'Manager'}
            )
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InventoryGlAccountMinimalTableCreator)

export default InventoryGlAccountMinimalTableCreator;