/* eslint-disable no-unused-vars */
import React, { Component, useState } from 'react'

import { gql } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Paper, Typography, Button } from '@material-ui/core'

import Loading from '../../components/Loading/Loading'
import MonthPicker from '../../components/MonthPicker/MonthPicker'
import CurrencyPicker from '../../components/CurrencyPicker/CurrencyPicker'
import GraphDrawer from './viewExpense'
import ViewUsageTab from './viewUsage'
import { useCurrentUser, useLastPublishedPeriod } from '../../redux/selectors'
import { longMonthNames } from '../../components/DateTools/MonthsNames'

const vendorsQuery = gql`
    query($period: Period) {
        activeCompanyVendors(period: $period) {
            id
            name
            country {
                name
            }
        }
    }
`

const REPORTS_QUERY = gql`
    query($period: Period, $vendors: [Int], $currency:String) {
        totalExpense(period: $period, vendors: $vendors, currency:$currency) {
            current
            threeMonths
            sixMonths
        }
        serviceNumbersCount(period: $period, vendors: $vendors) {
            current
            threeMonths
            sixMonths
        }
        serviceNumbersCost(period: $period, vendors: $vendors, currency:$currency) {
            current
            threeMonths
            sixMonths
        }
        expenseByTechnology(period: $period, vendors: $vendors, currency:$currency) {
            period
            dataByTechnology {
                technology
                totalCost
            }
        }
        expenseByVendor(period: $period, vendors: $vendors, currency:$currency) {
            dataByPeriod {
                period
                dataByVendor {
                    vendor {
                        name
                        id
                    }
                    totalCost
                    dataByChargeGroup {
                        chargeGroup {
                            name
                            id
                        }
                        cost
                    }
                }
            }
        }
        totalUsageByPeriod(period:$period,vendors:$vendors){
            period
            totalUsage{
                voice{
                    unit{
                        name
                    }
                    count
                }
                internet{
                    unit{
                        name
                    }
                    count
                }
                sms{
                    unit{
                        name
                    }
                    count
                }
            }
        }
        totalCountByVendor(period: $period, vendors: $vendors) {
            period
            dataByVendor {
                count
                vendor {
                    name
                    id
                }
            }
        }
    }
`

function getItemsFrom(obj) {
    return Object.keys(obj).map(
        function (key, index) {
            return { key: key, value: obj[key] }
        }
    )
}

class MultipleGroupedSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allVendors: this.props.allVendors,
            selectedElements: this.props.allVendors,
        }
    }

    handleSelectAllCheckBoxes = isChecked => {
        let selectedElements = [];

        if (isChecked) {
            selectedElements = this.state.allVendors;
            selectedElements.sort();
        }

        this.setState(state => {
            state.selectedElements = selectedElements;
            return state;
        })

        this.props.onChange(selectedElements);
    };

    selectAll = () => this.handleSelectAllCheckBoxes(true);
    deselectAll = () => this.handleSelectAllCheckBoxes(false);

    componentDidMount() {
        this.props.onChange(this.state.selectedElements);
    }

    onCheckboxChange = (id) => {
        let selectedElements = [...this.state.selectedElements];
        if (selectedElements.includes(id)) {
            selectedElements.splice(selectedElements.indexOf(id), 1);
        } else {
            selectedElements.push(id);
        }

        selectedElements.sort();

        this.setState(state => {
            state.selectedElements = selectedElements;
            return state;
        })

        this.props.onChange(selectedElements);
    }

    render() {
        const { t } = this.props
        return (
            <div className='w-100 mh-100' key="vendor_container_001">
                <Typography >{t("Vendors")}</Typography>
                <Divider className="bg-light-blue mt-3 mb-4" />
                <Box className='w-100'>
                    <Button onClick={this.selectAll} style={{ float: "left", display: "flex", margin: "0.0" }} size="small" color="primary" variant="contained">{t("Select All")}</Button>
                    <Button onClick={this.deselectAll} style={{ float: "right", display: "flex", margin: "0.0" }} size="small" color="primary" variant="outlined">{t("Deselect All")}</Button>
                </Box>
                <FormControl className="mt-3 w-100 mh-100 overflow-auto">
                    <FormGroup id="vendors_chart_list_container" className='w-100 overflow-auto'>
                        {getItemsFrom(this.props.items).map(
                            ({ key, value }, index) => (
                                <div key={"vendor_country__" + index} className="d-flex flex-column w-100">
                                    <Typography
                                        className="dashboard-vendor-name"
                                        key={key}
                                    >
                                        {key}
                                    </Typography>

                                    {value.map((data, index) => (
                                        <FormControlLabel
                                            className="pl-3"
                                            control={
                                                <Checkbox
                                                    checked={this.state.selectedElements.includes(data.id)}
                                                    onChange={() => this.onCheckboxChange(data.id)}
                                                />
                                            }
                                            label={data.name}
                                            key={"vendor_" + index}
                                        />
                                    ))}
                                </div>
                            )
                        )}
                    </FormGroup>
                </FormControl>
            </div>
        )
    }
}

const SelectorDeTodo = (props) => {
    const { t } = props

    const months = longMonthNames(props.t)

    const [selectedVendors, setSelectedVendors] = useState(props.savedVendors)
    const [currencyCode, setCurrencyCode] = useState(props.currency ? props.currency.code : null)
    const [currencySymbol, setCurrencySymbol] = useState(props.currency ? props.currency.symbol : null)

    const changeCurrency = (currencyCode, symbol) => {
        setCurrencyCode(currencyCode)
        setCurrencySymbol(symbol)
    }

    const onChangeVendors = (vendors) => {
        const newVendors = [...vendors].sort()
        setSelectedVendors(newVendors)
        props.onSaveVendors(newVendors)
    }

    const generateVariables = () => {
        return {
            period: props.period,
            vendors: selectedVendors.map(id => parseInt(id)),
            currency: currencyCode,
        }
    }

    const monthTransformer = (monthNumber) => {
        return (
            months[monthNumber - 1] + " " + props.year
        )
    }

        return (

            <Paper className="w-100 h-auto fleet-container">

                <div key="flex_period_selector_container" className="d-flex w-100 p-2">
                    <Typography className="fleet-month-title mr-auto m-1 ml-3" variant='h4'>
                        {monthTransformer(props.month)}
                    </Typography>
                    <span style={{ float: "right", fontSize: 18, color: "gray", width: "30%" }}>
                        <CurrencyPicker
                            selectedSymbol={currencySymbol}
                            selected={currencyCode}
                            onChangeCurrency={changeCurrency}
                            month={props.month}
                            year={props.year} />
                    </span>
                </div>


                <Divider className="bg-light-blue" />

                {/*<div className="w-100 position-relative mt-2">
                    <div className="d-flex dashboard-tab-container justify-content-end aling-content-center">
                        <Button className={`${props.tabs.viewExpense? "" : "un"}selected-tab`} onClick={()=>props.onChangeTab("viewExpense")} > {t("View Expense")}</Button>
                        <Button className={`${props.tabs.viewUsage? "" : "un"}selected-tab`} onClick={()=>props.onChangeTab("viewUsage")} > {t("View Usage")}</Button>
                    </div>
                </div>*/}

                <div key="flex_period_001" className="dashboard-filter-container p-3 mt-1">
                    <div key="flex_period_02" style={{ display: 'flex' }}>
                        <Typography style={{ marginTop: '5px', display: 'flex' }}>{t("Month")}
                        </Typography>
                        <MonthPicker className="ml-3"
                            month={props.month}
                            year={props.year}
                            onChangeDate={props.onChangeDate}
                            checkDisabledMonths={true}
                            t={t}
                        />
                    </div>
                    <div key="flex_period_003" className="mt-3">
                        <MultipleGroupedSelector t={t} refetch={props.refetch} allVendors={selectedVendors} items={props.vendors} onChange={onChangeVendors} />
                    </div>
                </div>
                <Loading query={REPORTS_QUERY} variables={generateVariables()}>
                    {(data) =>
                        <>
                            {props.tabs.viewExpense ?
                                <GraphDrawer t={t} currencySymbol={currencySymbol} dateTitle={monthTransformer(props.month)} data={data} />
                                : null}
                            {props.tabs.viewUsage ?
                                <ViewUsageTab period={props.period} vendors={selectedVendors} t={t} currencySymbol={currencySymbol} dateTitle={monthTransformer(props.month)} data={data} />
                                : null}
                        </>
                    }
                </Loading>
            </Paper>

        )
}

const Fleet = (props) => {
    const LastPublishedPeriod = useLastPublishedPeriod()
    const currentUser = useCurrentUser()

    const [year, setYear] = useState(LastPublishedPeriod.year)
    const [month, setMonth] = useState(LastPublishedPeriod.month)
    const [period, setPeriod] = useState(LastPublishedPeriod.year + "-" + LastPublishedPeriod.month)
    const [savedSelectedVendors, setSavedSelectedVendors] = useState(null)
    const [savedSelectedCurrency, setSavedSelectedCurrency] = useState(null)
    const [tabs, setTabs] = useState({ viewExpense: true, viewUsage: false })

    const onChangeDate = (year, month) => {
        setYear(year)
        setMonth(month)
        setPeriod(`${year}-${month}`)
    }

    const onChangeTab = (tab) => {
        let allTabs = { ...tabs }
        for (let _tab in allTabs) {
            allTabs[_tab] = false
        }
        setTabs({ ...allTabs, [tab]: true })
    }

    const saveSelectedVendors = (vendors) => {
        setSavedSelectedVendors(vendors)
    }

    const saveSelectedCurrency = (ccy) => {
        setSavedSelectedCurrency(ccy)
    }

    return (
        <div id="app">
            <Loading query={vendorsQuery} variables={{ period: period }}>
                {(data) => {
                    let vendorByCountry = {}
                    const allVendorsId = []
                    for (let vendor of data.activeCompanyVendors) {
                        if (!(vendor.country.name in vendorByCountry)) {
                            vendorByCountry[vendor.country.name] = [];
                        }
                        vendorByCountry[vendor.country.name].push({
                            id: vendor.id,
                            name: vendor.name,
                        })
                        allVendorsId.push(vendor.id)
                    }

                    return (
                        <SelectorDeTodo
                            t={props.t}
                            tabs={tabs}
                            year={year}
                            currency={savedSelectedCurrency ? savedSelectedCurrency : currentUser.currency}
                            month={month}
                            vendors={vendorByCountry}
                            savedVendors={savedSelectedVendors ? savedSelectedVendors : allVendorsId}
                            onSaveCurrency={saveSelectedCurrency}
                            onSaveVendors={saveSelectedVendors}
                            onChangeDate={onChangeDate}
                            onChangeTab={onChangeTab}
                            period={period}
                        />
                    )
                }}
            </Loading>
        </div>
    )
}

export default withTranslation('dashBoard')(Fleet)
