import React, { useState } from 'react'

import { withTranslation } from 'react-i18next'
import { useCurrentUser, useLastPublishedPeriod } from '../../../redux/selectors'
import ReportsFilter from '../../../components/ReportsFilter/ReportsFilter'
import InventoryReportTable from './InventoryReportTable'

function InventoryReport(props){
    const currentUser = useCurrentUser();
    const LastPublishedPeriod = useLastPublishedPeriod();

    const [date, setDate] = useState({year: LastPublishedPeriod.year, month: LastPublishedPeriod.month})
    const [currency_symbol, SetSymbol] = useState(currentUser.currency ? currentUser.currency.symbol : null)
    const [currency_id, SetCurrencyId] = useState(currentUser.currency ? currentUser.currency.code : null)

    const handleChangeCurrency = (currencyCode, symbol) =>{
        SetSymbol(symbol)
        SetCurrencyId(currencyCode)
    }

    function onChangeDate(year, month) {
        setDate({year: year, month: month});
    }

    function generateFilters(){
        return {period:`${date.year}-${date.month}`, currency: currency_id}
    }

    document.title = 'Inventory Report - BubbleBPM'

    return (
        <>
            <ReportsFilter 
                onChangeDate={onChangeDate}
                date={date}
                info={{label: props.t(`Inventory associated information by period`)}}
                onChangeCurrency={handleChangeCurrency}
                currencySelected = {currency_id}
                checkDisabledMonths={true}
            />
            <InventoryReportTable
                t={props.t}
                symbol={currency_symbol}
                currencyCode={currency_id}
                filters={generateFilters()}
            />
        </>
    )
}

export default withTranslation("InventoryReport")(InventoryReport)