import React, { useState } from 'react'

import { Dialog, DialogContent, DialogTitle, AppBar, Tabs, Tab, Typography, Badge, makeStyles } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import { ButtonTooltip } from '../../../Tooltips/BPMTooltips';
import { useTranslation } from 'react-i18next';
import { useTableCount } from '../../../../redux/selectors';

import TabPanel from '../../../../pages/Invoice/Invoice/components/TabPanel';

import SetupTableData from '../TableWrapper/SetupTableData';
import tableCreatorCreatorFactoryCreator from '../../TableCreatorFactory/TableCreatorCreatorFactoryCreator';

import InventoryUsersAssociationTableCreator from '../../../../tableDefinitions/Inventory/Associations/InventoryUsersAssociationTableCreator';
import InventoryDevicesAssociationTableCreator from '../../../../tableDefinitions/Inventory/Associations/InventoryDevicesAssociationTableCreator';
import InventorySitesAssociationTableCreator from '../../../../tableDefinitions/Inventory/Associations/InventorySitesAssociationTableCreator';
import InventoryCostCentersAssociationTableCreator from '../../../../tableDefinitions/Inventory/Associations/InventoryCostCentersAssociationTableCreator';
import InventoryGlAccountsAssociationTableCreator from '../../../../tableDefinitions/Inventory/Associations/InventoryGlAccountsAssociationTableCreator';

const AssociationsTabData = [
    { label: 'Device Associations', tableName: 'Inventory Devices Association', tableID: InventoryDevicesAssociationTableCreator.getId() },
    { label: 'User Associations', tableName: 'Inventory Users Association', tableID: InventoryUsersAssociationTableCreator.getId() },
    { label: 'Site Associations', tableName: 'Inventory Sites Association', tableID: InventorySitesAssociationTableCreator.getId() },
    { label: 'GlAccounts Associations', tableName: 'Inventory GlAccounts Association', tableID: InventoryGlAccountsAssociationTableCreator.getId() },
    { label: 'Cost Center Associations', tableName: 'Inventory CostCenters Association', tableID: InventoryCostCentersAssociationTableCreator.getId() }
]

function InventoryAssociationTable(props) {
    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(props.associationTable.tableID, null)
    const tableCreator = tableCreatorCreator.getTableCreator(props.associationTable.tableName);
    tableCreator.setAdditionalFilters({serviceNumber: props.serviceNumber})
    tableCreator.setInitialSortField('dateFrom', 'asc')

    return (
        <SetupTableData
            TableCreator={tableCreator}
            stickyHeader
        />
    )
}

const useStyles = makeStyles({
    dialog: {
        position: 'absolute',
        height: '80%'
    }
});

function InventoryAssociationsHistoryOpener(props) {
    const { t } = useTranslation('inventoryAssociations')
    const { data } = props

    const [isOpen, setOpen] = useState(false)
    const [tabValue, setTabValue] = useState(0)

    // const usersAssociationTableCountSelector = useTableCount(AssociationsTabData.usersAssociation.tableName)
    // const devicesAssociationTableCountSelector = useTableCount(AssociationsTabData.devicecAssociation.tableName)
    // const sitessAssociationsTableCountSelector = useTableCount(AssociationsTabData.sitesAssociation.tableName)
    // const glAccountsAssociationsTableCountSelector = useTableCount(AssociationsTabData.glAccountsAssociation.tableName)
    // const costCentersAssociationsTableCountSelector = useTableCount(AssociationsTabData.costCentersAssociation.tableName)

    const classes = useStyles();

    const setBadge = (tittle, dataCount) => {
        return (
            <Badge color="primary"
                style={{ padding: "0 15px" }}
                badgeContent={dataCount}>
                {tittle}
            </Badge>
        )
    }

    return (
        <div>
            <ButtonTooltip title={t('Inventory Associations History')} onClick={() => (setOpen(true))}>
                <HistoryIcon style={{ color: '#6c6c6c', fontSize: '24px' }} />
            </ButtonTooltip>
            <Dialog
                open={isOpen}
                onClose={() => (setOpen(false))}
                maxWidth='lg'
                id={'HistoryPickerDialog'}
                classes={{ paper: classes.dialog }}
            >
                <DialogTitle id="scroll-dialog-title" style={{ fontSize: 15 }}>
                    <span style={{ fontSize: 25 }}>  {t("Inventory Associations History")}  </span>
                    <span onClick={() => (setOpen(false))} style={{ fontSize: '1.5em', cursor: 'pointer', position: 'absolute', right: '10px', top: '5px' }}>&times;</span>
                </DialogTitle>
                <DialogContent>
                    <div style={{ marginBottom: 10 }}>
                        <Typography variant="h6">{`${t("Service Number")}: ${data.inventory.serviceNumber}`} </Typography>
                    </div>
                    <AppBar position="sticky" color="default" style={{ marginBottom: 10 }}>
                        <Tabs
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            scrollButtons="auto"
                        >
                            {AssociationsTabData.map((tabData, index) =>
                                <Tab
                                    key={"tab_" + index}
                                    label={tabData.label}
                                    index={index}
                                    onClick={() => setTabValue(index)}
                                />
                            )}
                        </Tabs>
                    </AppBar>
                    {AssociationsTabData.map((tabData, index) =>
                        <TabPanel key={index} value={tabValue} index={index}>
                            <InventoryAssociationTable serviceNumber={data.inventory.serviceNumber} associationTable={tabData} t={t} />
                        </TabPanel>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default InventoryAssociationsHistoryOpener
