/* eslint-disable eqeqeq */
import React from 'react'
import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    DataTransformer, 
    NullClickStrategy, 
    OnlyFilterActionDisplayStrategy, 
    TableFieldDescription, 
    StatusChipComponentCreator,
    ProcessControlFileDownloadComponentCreator,
    FileDownloadlDisplayCreator,
} from '../../../components/BPMTable/TableMapperCreator'
import { ExportFooterItemCreator } from '../../../components/BPMTable/TableFooterItems'
import { checkProcessDuration } from '../../../utils/processControlUtils'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query MyMassiveLoadTemplates(
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,
        $state:String,
        $modelName:String,
        $createdAt:String,
    ) {
        myMassiveLoadTemplates(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            state:$state,
            modelName:$modelName,
            createdAt:$createdAt,
        ) {
            edges {
                node {
                    id
                    user {
                        id
                        email
                    }
                    state
                    createdAt
                    updatedAt
                    duration
                    message
                    modelName
                    file
                    fileInfo {
                        state
                        file
                    }
                    invoice {
                        id
                        identifier
                        period {
                            id
                            date
                        }
                        account {
                            id
                            name
                        }
                    }
            }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`


const queries = {tableQuery};

class DataLoadTemplatesTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "DataLoadTemplatesTableCreator"
    }

    getTableCreator = (tableName) => {
            
        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, queries, 
                                                    new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())

        tableCreator.setAdditionalFilters({first: 30})
        tableCreator.setInitialSortField('createdAt', 'desc')
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()]);

        tableCreator.setDescriptions([
            new TableFieldDescription(
                'user',
                new DataMapper("user.email"),
                new f.FilterDescription(new f.TextFilter("user")),
                {label: 'User', onlyForm: true}
            ),
            new TableFieldDescription(
                'modelName',
                new DataMapper("modelName"),
                new f.FilterDescription(new f.TextFilter('modelName')),
                {label: 'Model Name'}
            ),
            new TableFieldDescription(
                'createdAt',
                new DataMapper("createdAt"),
                new f.FilterDescription(new f.DateFilter("createdAt")),
                {label: 'Creation Date', transform: new DataTransformer(({createdAt}) => createdAt.split('.')[0].replace('T',' '))}
            ),
            new TableFieldDescription(
                'duration',
                new DataMapper("duration", "createdAt", "state"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Duration', transform: new DataTransformer(({duration, createdAt, state}) => checkProcessDuration(duration, createdAt, state))}
            ),
            new TableFieldDescription(
                'state',
                new DataMapper("state"),
                new f.FilterDescription(new f.ProcessControlStateFilter("state")),
                {label: 'Status', componentCreator: new StatusChipComponentCreator()}
            ),
            new TableFieldDescription(
                'downloadFile',
                new DataMapper("id", "file", "createdAt", "modelName"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: 'File', 
                    hideSort: true, 
                    excelDisplay : new FileDownloadlDisplayCreator(),
                    componentCreator: new ProcessControlFileDownloadComponentCreator(),
                    transform: new DataTransformer(({id, file, createdAt, modelName}) => ({path:'/massive_uploads/data_templates/', id:id, file:file, createdAt:createdAt, model:modelName}))
                }
            ),
        ])
 
        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(DataLoadTemplatesTableCreator)

export default DataLoadTemplatesTableCreator;