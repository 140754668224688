import React from 'react'

import { withTranslation } from 'react-i18next'
import { Paper, Typography } from '@material-ui/core'

const NotFound = (props) => {
    document.title = 'Not Found - BubbleBPM'
    return (
        <div style={{diplay: 'flex', justifyContent: 'center'}}>
            <Paper style={{height: 200, padding: 20, margin: 5, textAlign: 'center'}}>
                <Typography variant='h2'>
                    {props.t('Oops site not found...')}
                </Typography>
            </Paper>
        </div>
    )
}

export default withTranslation('notFound')(NotFound)