import React, { useState, useEffect } from 'react'

import { withTranslation } from 'react-i18next'
import { useApolloClient, ApolloConsumer } from '@apollo/client'
import {
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    LinearProgress,
    DialogContent,
    withStyles
} from '@material-ui/core'

import SetupTableData from '../../../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'

import FormData from '../../../../components/BPMTable/TableComponents/Mutation/MutationSetup'
import { 
    customPopUpContentDisplayStrategy,
    customFormConfirmStrategy,
    customFormCancelStrategy,
    InventoryValidationDisplayStrategy,
    ValidateSelectedInventoriesStrategy
} from '../../../../components/BPMTable/TableMapperCreator'
import tableCreatorCreatorFactoryCreator from '../../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import formCreatorCreatorFactoryCreator from '../../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import InventoryFormCreator from '../../../../tableDefinitions/Inventory/Inventory/InventoryFormCreator'
import { InventoryTableCreator } from '../../../../tableDefinitions/Inventory/Inventory/InventoryTableCreator'
import { QUERY, MUTATION, VALIDATE_SELECTED_MUTATION } from './mutations/InventoryQuery'
import {ValidatationFooterItemCreator, ExportFooterItemCreator, MassiveUploadFooterItemCreator} from "../../../../components/BPMTable/TableFooterItems"

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#a7cbc7',
  },
  barColorPrimary: {
    backgroundColor: '#075e54',
  },
})(LinearProgress);


function InventoryValidation(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [warningDisplay, setWarningDisplay] = useState(false)
    const { invoiceId, t, refetch, changeTab, serviceNumbers, updateCount } = props;
    const client = useApolloClient()
    
    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(InventoryTableCreator.getId(), {tcFilters: {invoice: invoiceId}})
    const inventoryTableCreator = tableCreatorCreator.getTableCreator('Invoice Inventory')
    
    inventoryTableCreator.setTableQuery(QUERY);
    inventoryTableCreator.setDisplayStrategy(new InventoryValidationDisplayStrategy({headerStyles:"table-layout: fixed;", bodyStyles:' '}));
    inventoryTableCreator.setPopupContentStrategy( new customPopUpContentDisplayStrategy(InventoryPopupForm));
    inventoryTableCreator.setSelectedRowActionsStrategy( new ValidateSelectedInventoriesStrategy(ValidateSelectedInventoriesStrat));
    inventoryTableCreator.setTableFooterItems([new ExportFooterItemCreator(), new MassiveUploadFooterItemCreator(), new ValidatationFooterItemCreator(openWarning)])

    var formCreatorCreator = formCreatorCreatorFactoryCreator.getFormCreatorCreatorFromIdentifier(InventoryFormCreator.getId(), null)
    const inventoryFormCreator = formCreatorCreator.getFormCreator()
    
    function InventoryPopupForm(props){
        const id = props.data.rawData.inventory.id
        inventoryFormCreator.setOnCancelFormStrategy(new customFormCancelStrategy(props.close))
        const confirmStrat = () => {
            props.handleSetFilters()
            props.close()
        }
        inventoryFormCreator.setOnConfirmFormStrategy(new customFormConfirmStrategy(confirmStrat))
        const FormInventory = inventoryFormCreator.create()
        return(
            <div style={{padding:30, width:'90vw'}}>
                <ApolloConsumer>
                    { client => <FormData extraVariables={{id:id}} TableObject={FormInventory} mutate='edit' client={client}/> }
                </ApolloConsumer>
            </div>
        )
    }
    
    function ValidateSelectedInventoriesStrat(props){
        if (props.allRowsSelected) {
            validateAll()
            return
        }

        props.client.mutate({
            mutation: VALIDATE_SELECTED_MUTATION,
            variables : {selectedInventories: props.selectedRows, allRecords: props.allRowsSelected, invoice: invoiceId, filters: JSON.stringify(props.filters)}
        }).then(
            res => {
                props.closePopper()
                props.clearAllSelections()
                props.refreshTable()

                updateCount(serviceNumbers-props.selectedRows.length)
           }
        ).catch(
            err => console.log(err)
        )
    }

    function openWarning(){
        setWarningDisplay(true)
    }

    function closeWarning(){
        setWarningDisplay(false)
    }

    function validateAll(){
        setIsLoading(true)
        client.mutate({
           mutation: MUTATION,
           variables: {invoice: invoiceId}
        }).then(
            () => {
                refetch().then(
                    () => {
                        setIsLoading(false)
                        closeWarning()
                    }
                ).then(updateCount(0))
        }).catch(
            err => {
                console.log(err);
                setIsLoading(false);
            }
        )
    }

    return (
        <div style={{marginTop:"10px"}}>
            <SetupTableData
                TableCreator={inventoryTableCreator}
                MassiveUploadTemplateUrlAppend={invoiceId}
            />

            <Dialog open={warningDisplay} onClose={closeWarning}>
                <DialogTitle>{serviceNumbers + ' ' + t('will be validated')}</DialogTitle>
                <DialogContent style={{height:35}}>
                {
                    isLoading &&
                        <ColorLinearProgress style={{marginBottom:5}} variant="query" />
                }
                </DialogContent>
                <DialogActions>

                    <Button size="small"
                            color="primary"
                            disabled={isLoading}
                            onClick={validateAll}
                            variant='contained'>
                        {t('Confirm')}
                    </Button>

                    <Button size="small"
                            variant='contained'
                            onClick={closeWarning}
                            style={{marginRight: 20}} >
                        {t('Cancel')}
                    </Button>

                </DialogActions>

            </Dialog>
        </div>
    )
}

export default withTranslation()(InventoryValidation);