import React, { useState } from 'react'

import { withTranslation } from 'react-i18next'
import { Typography, Paper, Button, Divider, IconButton } from '@material-ui/core'
import { ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon, Cancel as CancelIcon } from '@material-ui/icons'
import { useFirstPublishedPeriod, useLastPublishedPeriod, useFirstPeriodForProcessedInvoices, useLatestPeriodForProcessedInvoices } from '../../redux/selectors'
import { shortMonthNames } from '../DateTools/MonthsNames'

const PeriodsGrid = (props) => {
    const [year, setYear] = useState(props.year || null);
    const FirstPublishedPeriod = useFirstPublishedPeriod()
    const LastPublishedPeriod = useLastPublishedPeriod()
    const FirstPeriodForProcessedInvoices = useFirstPeriodForProcessedInvoices()
    const LatestPeriodForProcessedInvoices = useLatestPeriodForProcessedInvoices()
    const months = shortMonthNames(props.t)

    const getFirstRealPublishedPeriod = () => {
        return props.useInvoicePeriods ? FirstPeriodForProcessedInvoices : FirstPublishedPeriod
    }

    const getLastRealPublishedPeriod = () => {
        return props.useInvoicePeriods ? LatestPeriodForProcessedInvoices : LastPublishedPeriod
    }

    const addYear = () => {
        if ((year + 1) >= getLastRealPublishedPeriod().year) {
            setYear(getLastRealPublishedPeriod().year)
        } else {
            setYear(year + 1)
        }
    }

    const reduceYear = () => {
        if ((year - 1) <= getFirstRealPublishedPeriod().year) {
            setYear(getFirstRealPublishedPeriod().year)
        } else {
            setYear(year - 1)
        }

    }

    const handleChangeYear = (aYear) => {
        setYear(aYear);
    }

    const handleChangeDate = (aMonth) => {
        props.setDate(year, aMonth)
    }

    const checkPrevNextYearIsDisabled = (checkNext) => {
        if ((typeof props.checkDisabledMonths !== 'undefined') && !props.checkDisabledMonths) {
            return false
        }

        return checkNext ? year >= getLastRealPublishedPeriod().year : year <= getFirstRealPublishedPeriod().year
    }

    const checkMonthIsDisabled = (month) => {
        if ((typeof props.checkDisabledMonths !== 'undefined') && !props.checkDisabledMonths) {
            return false
        }

        if (getFirstRealPublishedPeriod().year === getLastRealPublishedPeriod().year || year === getLastRealPublishedPeriod().year) {
            return month > getLastRealPublishedPeriod().month
        } else if (year === getFirstRealPublishedPeriod().year) {
            return month < getFirstRealPublishedPeriod().month
        } else {
            return (year < getFirstRealPublishedPeriod().year || year > getLastRealPublishedPeriod().year)
        }
    }

    return (
        <Paper style={{ position: 'relative', float: 'center' }}>
            {/* <- YYYY -> (x) */}
            <div>
                <Typography style={{ textAlign: 'center' }}>
                    {props.showInnerIcon ?
                        <CancelIcon fontSize='small' style={{ position: 'absolute', right: 3, top: 3, cursor: 'pointer' }} onClick={props.onDelete} />
                        : null}
                    <IconButton disabled={checkPrevNextYearIsDisabled(false)} onClick={() => reduceYear()}>
                        <ArrowBackIosIcon fontSize='small' />
                    </IconButton>
                    {year}
                    <IconButton disabled={checkPrevNextYearIsDisabled(true)} onClick={() => addYear()}>
                        <ArrowForwardIosIcon fontSize='small' />
                    </IconButton>
                </Typography>
            </div>
            <Divider />
            {/* Jan Feb Mar */}
            <div>
                <Button disabled={checkMonthIsDisabled(1)} onClick={() => handleChangeDate(1)} color='primary'>{months[0]}</Button>
                <Button disabled={checkMonthIsDisabled(2)} onClick={() => handleChangeDate(2)} color='primary'>{months[1]}</Button>
                <Button disabled={checkMonthIsDisabled(3)} onClick={() => handleChangeDate(3)} color='primary'>{months[2]}</Button>
            </div>
            {/* Apr May Jun */}
            <div>
                <Button disabled={checkMonthIsDisabled(4)} onClick={() => handleChangeDate(4)} color='primary'>{months[3]}</Button>
                <Button disabled={checkMonthIsDisabled(5)} onClick={() => handleChangeDate(5)} color='primary'>{months[4]}</Button>
                <Button disabled={checkMonthIsDisabled(6)} onClick={() => handleChangeDate(6)} color='primary'>{months[5]}</Button>
            </div>
            {/* Jul Aug Sep */}
            <div>
                <Button disabled={checkMonthIsDisabled(7)} onClick={() => handleChangeDate(7)} color='primary'>{months[6]}</Button>
                <Button disabled={checkMonthIsDisabled(8)} onClick={() => handleChangeDate(8)} color='primary'>{months[7]}</Button>
                <Button disabled={checkMonthIsDisabled(9)} onClick={() => handleChangeDate(9)} color='primary'>{months[8]}</Button>
            </div>
            {/* Oct Nov Dic */}
            <div>
                <Button disabled={checkMonthIsDisabled(10)} onClick={() => handleChangeDate(10)} color='primary'>{months[9]}</Button>
                <Button disabled={checkMonthIsDisabled(11)} onClick={() => handleChangeDate(11)} color='primary'>{months[10]}</Button>
                <Button disabled={checkMonthIsDisabled(12)} onClick={() => handleChangeDate(12)} color='primary'>{months[11]}</Button>
            </div>
        </Paper>
    )
}

export default withTranslation('PeriodsGrid')(PeriodsGrid)