import React from 'react'

import PrivateRoute from '../components/PrivateRoute/PrivateRoute'

//Reports
import Home from '../pages/Home/Home'
import Consumptions from '../pages/Consumptions/Consumptions'
import MyTeam from '../pages/MyTeam/MyTeam'
import Fleet from '../pages/Fleet/Fleet'
import DeviceReport from "../pages/LossReport/LossReport"
import Optimization from "../pages/optimization/Optimization"
import ExpensesBySite from '../pages/ExpensesBySite/ExpensesBySite'
import Ranking from '../pages/Ranking/Ranking'
import InventoryReport from '../pages/Inventory/InventoryReport/InventoryReport'

//Profile
import Profile from '../pages/Profile/Profile'
import Password from '../pages/Password/Password'

// Billing
import ConceptConverter from '../pages/Billing/Converters/Concept'
import Concept from '../pages/Billing/Concept/Concept'
import DestinationConverter from '../pages/Billing/Converters/Destination'
import Destination from '../pages/Billing/Destination/Destination'
import ChargeGroupConcept from '../pages/Billing/ChargeGroupConcept/ChargeGroupConcept'
import ChargeGroup from '../pages/Billing/ChargeGroup/ChargeGroup'
import UploadBilling from '../pages/Billing/UploadBilling/UploadBilling'
import ChargeGroupUssage from "../pages/Billing/CharGroupUssage/ChargeGroupUssage"

// Invoicing
import WebCredentials from '../pages/Invoice/WebCredentials/WebCredentials'
import Invoice from '../pages/Invoice/Invoice/Invoice'
import InvoicePayment from '../pages/Invoice/InvoicePayment/InvoicePayment'
import InvoicePaymentGroup from '../pages/Invoice/InvoicePayment/Group'
import Periods from '../pages/Invoice/Periods/Periods'
import BillingAccounts from '../pages/Billing/BillingAccount/BillingAccounts'

// Inventory
import Account from '../pages/Inventory/Account/Account'
import LegalEntity from '../pages/Inventory/LegalEntity/LegalEntity'
import User from '../pages/Inventory/User/User'
import CostCenter from '../pages/Inventory/CostCenter/CostCenter'
import Devices from '../pages/Inventory/Devices/Devices'
import glAccount from '../pages/Inventory/glAccount/glAccount'
import Sites from '../pages/Inventory/Sites/Sites'
import Inventory from '../pages/Inventory/Inventory/Inventory'
import ConsolidatedSBO from "../pages/consolidatedSBO/consolidatedSBO"
// Jobs
import ExcelExports from '../pages/Jobs/ExcelExports/ExcelExports'
import DataLoadTemplates from '../pages/Jobs/DataLoadTemplates/DataLoadTemplates'
import MassiveUploadControl from '../pages/Jobs/MassiveUploadControl/MassiveUploadControl'
import Process from '../pages/Jobs/Process/Process'

// Contract
import Contract from '../pages/Contract/Contract'

import NotFound from '../pages/NotFound/NotFound'
import GetApolloClient from '../utils/getApolloClient'

export default (
    <>
        {/* Reports */}
        <PrivateRoute exact path='/' component={Home} />
        <PrivateRoute exact path='/consumptions' component={Consumptions} />
        <PrivateRoute path='/myTeam/' component={MyTeam} />
        <PrivateRoute exact path='/fleetMetrics' component={Fleet} />
        <PrivateRoute exact path='/lossreport' component={DeviceReport}/>
        <PrivateRoute path='/consolidatedSBO/' component={ConsolidatedSBO}/>
        <PrivateRoute path="/optimization/" component={Optimization} />
        <PrivateRoute path="/expensesBySite/" component={ExpensesBySite} />
        <PrivateRoute path="/ranking/" component={Ranking} />
        <PrivateRoute path="/inventoryReport/" component={InventoryReport} />

        {/* Profile */}
        <PrivateRoute exact path='/profile' component={Profile} />
        <PrivateRoute exact path='/password' component={Password} />
        
        {/* Billing */}
        <PrivateRoute path='/converters/concept' component={ConceptConverter} />
        <PrivateRoute path='/concept' component={Concept} />
        <PrivateRoute path='/converters/destination' component={DestinationConverter} />
        <PrivateRoute path='/destination' component={Destination} />
        <PrivateRoute path='/chargeGroupConcept' component={ChargeGroupConcept} />
        <PrivateRoute path='/chargeGroup' component={ChargeGroup} />
        <PrivateRoute path='/backoffice/upload_billing'>
            <GetApolloClient>
                {client => <UploadBilling client={client} />}
            </GetApolloClient>
        </PrivateRoute>
        <PrivateRoute path='/chargeGroupUssage' component={ChargeGroupUssage} />
        
        {/* Invoicing */}
        <PrivateRoute path='/webcredentials' component={WebCredentials} />
        <PrivateRoute path='/invoice' component={Invoice} />
        <PrivateRoute path='/invoicePayment' component={InvoicePayment} />
        <PrivateRoute path='/apgl' component={InvoicePaymentGroup} />
        <PrivateRoute path='/periods' component={Periods} />
        <PrivateRoute path='/billingAccounts' component={BillingAccounts} />

        {/* Inventory */}
        <PrivateRoute path='/account' component={Account} />
        <PrivateRoute path='/legalEntity' component={LegalEntity} />
        <PrivateRoute path='/user' component={User} />
        <PrivateRoute path='/costCenter' component={CostCenter} />
        <PrivateRoute path='/devices' component={Devices} />
        <PrivateRoute path='/glAccount' component={glAccount} />
        <PrivateRoute path='/sites' component={Sites} />
        <PrivateRoute path='/inventory' component={Inventory} />

        {/* Jobs */}
        <PrivateRoute path='/excelExports' component={ExcelExports} />
        <PrivateRoute path='/dataLoadTemplates' component={DataLoadTemplates} />
        <PrivateRoute path='/massiveUploadControl' component={MassiveUploadControl} />
        <PrivateRoute path='/processControl' component={Process} />

        {/* Contract */}
        <PrivateRoute path='/contract' component={Contract} />
    </>
);