/* eslint-disable eqeqeq */
import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper,
    DataTransformer,
    PeriodDisplayStrategy, 
    PeriodSelectedActions, 
    periodToExcelTransformer, 
    TableFieldDescription
} from '../../../components/BPMTable/TableMapperCreator'
import {ExportFooterItemCreator} from '../../../components/BPMTable/TableFooterItems'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'


const tableQuery = gql`
    query AllCompanyPeriod(
        $orderField: String,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $state: String,
        $startDate: String,
        $endDate: String,
    ) {
        allCompanyPeriod(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            state:$state,
            endDate:$endDate,
            startDate:$startDate
        ) {
            edges {
                node {
                    period {
                        id
                        endDate
                        startDate
                    }
                    id
                    state
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const newMutator = gql`
    mutation createCompanyPeriodMutation($periodId: String) {
        createCompanyPeriod(periodId: $periodId) {
            companyPeriod {
                id
                period {
                    date
                }
            }
        }
    }
`
const unitQuery = `
    companyPeriod(id: $id) {
        id
        state
        period {
            id
            endDate
            startDate
        }
    }
`

const allPeriods = `
    allPeriods {
        edges {
            node {
                id
                startDate
                endDate
            }
        }
    }
`

const InvoiceInvoicePeriodsTableCreatorQueries = {tableQuery, newMutator, unitQuery}

class InvoiceInvoicePeriodsTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InvoiceInvoicePeriodsTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, InvoiceInvoicePeriodsTableCreatorQueries)
        tableCreator.setAdditionalFilters({first:30})
        tableCreator.setInitialSortField('startDate', 'desc')
        tableCreator.setSelectedRowActionsStrategy( new PeriodSelectedActions())
        tableCreator.setDisplayStrategy(new PeriodDisplayStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()])
        tableCreator.setDescriptions([
            new TableFieldDescription(
                'state',
                new DataMapper('state'),
                new f.FilterDescription(new f.PeriosStateFilter('state')),
                {label: ('State'), transform: new DataTransformer(({state}) => state == 'A_1' ? 'Published' : 'Unpublished')}
            ),
            new TableFieldDescription(
                'startDate',
                new DataMapper('period.startDate'),
                new f.FilterDescription(new f.DateFilter('startDate')),
                {label: ('Start Date'), excelTransform: new DataTransformer(({startDate}) => periodToExcelTransformer(startDate))}
            ),
            new TableFieldDescription(
                'endDate',
                new DataMapper('period.endDate'),
                new f.FilterDescription(new f.DateFilter('endDate')),
                {label: ('End Date'), excelTransform: new DataTransformer(({endDate}) => periodToExcelTransformer(endDate))}
            )
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InvoiceInvoicePeriodsTableCreator)

export {
    InvoiceInvoicePeriodsTableCreator,
    InvoiceInvoicePeriodsTableCreatorQueries
}