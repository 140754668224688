import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import InventoryGlAccountMinimalTableCreator from '../../../tableDefinitions/Inventory/GlAccount/InventoryGlAccountMinimalTableCreator'

export default class GlAccountMinimalTable{
    constructor(filters) {
        this.filters = filters
        this.tableFormMapper = [
            {mapper:data=>data.displayName, label:"Name"},
            {mapper:data=>data.type, label:"Type"},
            {mapper:data=>data.domain, label:"Domain"},
        ]
    }

    getTableCreator = () => {
        var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(InventoryGlAccountMinimalTableCreator.getId(), {tcFilters: this.filters})
        return tableCreatorCreator.getTableCreator('Gl Account')
    }
}