import React from 'react'

import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import JobsProcessTableCreator from '../../../tableDefinitions/Jobs/Process/JobsProcessTableCreator'

const Process = (props) => {
    document.title = `Process Control - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Process Control'}
            tableCreatorCreatorId={JobsProcessTableCreator.getId()}
            path = {props.match.url}
        />
    )
}

export default Process