/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
import React, { useRef, useState } from 'react'

import _ from 'lodash'

import i18n from 'i18next'
import { TableCell, Select, MenuItem, OutlinedInput, InputAdornment, Popper, Paper, ClickAwayListener, Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import CheckboxListInput  from './TableComponents/InputComponents/CheckboxListInput'
import DateInput from './TableComponents/InputComponents/DateInput'
import DropDownInput from './TableComponents/InputComponents/DropDownInput'
import DropDownSearch from './TableComponents/InputComponents/DropDownSearch'
import InventoryAssociationHelper  from './TableComponents/InputComponents/InputForInventoryAssociation'
import MultiPicker  from './TableComponents/InputComponents/MultiPickerInput'
import TableMultiPicker  from './TableComponents/InputComponents/TableMultiPickerInput'
import TableMultiPicker2 from './TableComponents/InputComponents/TableMultiPickerInput2'
import OptionsDropDownInput from './TableComponents/InputComponents/OptionsDropDownInput'
import PasswordInput from './TableComponents/InputComponents/PasswordInput'
import SwitchInput from './TableComponents/InputComponents/SwitchInput'
import TableSearchPicker  from './TableComponents/InputComponents/TablePicker'
import TextAreaInput from './TableComponents/InputComponents/TextAreaInput'
import ModelInput from './TableComponents/InputComponents/ModelInput'
import UploadInput from "./TableComponents/InputComponents/UploadInput"
import TextInput from './TableComponents/InputComponents/TextInput'
import NameValueInput from './TableComponents/InputComponents/NameValueInput'

import MonthPickerFilter from '../MonthPickerFilter/MonthPickerFilter'

const filterLabel = i18n.t('placeholder:Filter');

export const CustomTableCellInput = withStyles(theme => ({
    head: {
      backgroundColor: "#55758C",
      color: theme.palette.common.white,
    },
    root:{
      padding: '5px 5px 5px 5px',
      borderBottom:'1px solid #0A1F2E',
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

export const CustomOutlinedInput= withStyles (theme => ({
    input: {
        padding: '9px 14px',
        height: '17px',
        color:'white',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    root: {
    "& $notchedOutline": {
    },
    "&:hover $notchedOutline": {
      borderColor: '#205982'
    },
    "&$focused $notchedOutline": {
      borderColor: '#205982'
    }
  },
  focused: {},
  notchedOutline: {}
}))(OutlinedInput);

const CustomOutlinedDropdownInput= withStyles (theme => ({
    input: {
        padding: '9px 14px',
        paddingLeft:'5px',
        paddingBottom:'5px',
        height: '17px',
        color:'white',
    },
    root: {
    "& $notchedOutline": {
    },
    "&:hover $notchedOutline": {
      borderColor: '#205982'
    },
    "&$focused $notchedOutline": {
      borderColor: '#205982'
    }
  },
  focused: {},
  notchedOutline: {}
}))(OutlinedInput);


class ComponentCreator{
    constructor(id, defaultValue=null){
        this.id = id;
        this.defaultValue = defaultValue;
    }

    getDefaultValue = () => {
        return this.defaultValue;
    }

    supportQueries = (props)=>[]
}

class TextAreaCreator extends ComponentCreator{
    type = 'text_area';

    create_with = (props)=>{
        return React.createElement(TextAreaInput, { ...props, inputId:this.id, defaultValue:this.defaultValue })
    }
}

class ModelCreator extends ComponentCreator{
    type = 'text_area';
    constructor(id, minimalTableInstance){
        super(id)
        this.minimalTable = minimalTableInstance;
    }
    create_with = (props)=>{
        return React.createElement(
            ModelInput, 
            {
                ...props, 
                inputId:this.id, 
                mappers:this.minimalTable.tableFormMapper || [], 
                tableCreator:this.minimalTable.getTableCreator(),
                defaultValue:this.defaultValue
            })
    }
}

class UploadCreator extends ComponentCreator{
    type = 'upload';
    constructor(id, downloadUrl){
        super(id)
        this.downloadUrl = downloadUrl;
    }
    create_with = (props)=>{
        return React.createElement(UploadInput, { ...props, inputId:this.id, downloadUrl: this.downloadUrl, defaultValue:this.defaultValue })
    }
}

class PasswordCreator extends ComponentCreator{
    type = 'password';

    create_with(props){
        return React.createElement(PasswordInput, { ...props, inputId:this.id, defaultValue:this.defaultValue })
    }
}

function NullTextFilterComponent(props) {
    const [isOpen, setOpen] = useState(false)
    const [filterValue, setValue] = useState("")
    const {onChange, value, id} = props
    const [empty, setEmpty] = useState(false)
    const textFilter = useRef(null)

    const onChangeFilter = (e) => {
        const event = {target:{name:id,value:{showNull:empty,value:e.currentTarget.value}}}
        setValue(e.currentTarget.value)
        onChange(event)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleClick = e => {
        setOpen(true)
    }

    const emptyFilter = e => {
        const event = {target:{name:id,value:{showNull:!empty,value:filterValue} || ""}}
        setEmpty(!empty)
        empty ?  onChange(event, false) :  onChange(event, true)
    }

    const options = [
        {
            label:"Empty",
            isSelected:empty,
            onSelected: emptyFilter,
        }
    ]

    return (
        <>
            <CustomTableCellInput   key={"cell" + id}  style={{fontSize:16}}>
                <div ref={textFilter}>
                    <CustomOutlinedInput
                        labelWidth={0}
                        placeholder={filterLabel}
                        onChange={(e)=> onChangeFilter(e)}
                        style={{width:'100%',fontSize:15}}
                        name={id}
                        endAdornment={
                            <InputAdornment position="end">
                                    <ArrowDropDownIcon style={{color:"white", cursor:"pointer"}} fontSize="small" onClick={handleClick}/>
                            </InputAdornment>
                            }
                    />
                </div>
            </CustomTableCellInput>
            <Popper anchorEl={textFilter.current} open={isOpen} placement="bottom" popperOptions={{onCreate: (data) => data.instance.popper.style.zIndex = 1}}>
                <ClickAwayListener onClickAway={handleClose}>
                    <Paper style={{position:"relative"}}>
                        {options.map((option, index) =>
                            <div key={index} style={{display: 'flex'}}>
                                <Checkbox checked={option.isSelected} style={{float:"left"}} onChange={option.onSelected}/>
                                <MenuItem onClick={option.onSelected} disabled={!option.isSelected}>{option.label}</MenuItem>
                            </div>
                        )}
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}

class TextFilter{
    constructor(id){
        this.id = id;
    }
    filterCreator(props){
        return(
              <CustomTableCellInput  key={"cell" + props.key}  style={{fontSize:16}}>
                 <CustomOutlinedInput
                    labelWidth={0}
                    autoComplete='off'
                    placeholder={filterLabel}
                    onChange={props.onChange}
                    value={props.filters[this.id] || ""}
                    style={{width:'100%',fontSize:15}}
                    name={this.id} />
              </CustomTableCellInput>

        )
    }
}

class NullTextFilter {
    constructor(id) {
        this.id = id;
    }
    filterCreator(props) {
        return (
            <NullTextFilterComponent
                key={props.key}
                onChange={props.onChange}
                value={props.filters[this.id] || ""}
                id={this.id}
            />
        )
    }
}

class NameValueCreator extends ComponentCreator{
    type = 'name_value';

    create_with = (props)=>{
        return React.createElement(NameValueInput, { ...props, inputId:this.id, defaultValue:this.defaultValue })
    }
}

class TextCreator extends ComponentCreator{
    type = 'text';

    create_with = (props)=>{
        return React.createElement(TextInput, { ...props, inputId:this.id, defaultValue:this.defaultValue })
    }
}

class DropDownSearchCreator extends ComponentCreator{
    type = 'drop_down';
    constructor(id, nullable){
        super(id)
        this.nullable = (nullable === undefined) || nullable;
    }

    create_with = (props)=>{
        return React.createElement(DropDownSearch, { ...props, inputId:this.id, nullable: this.nullable, defaultValue:this.defaultValue})
    }
}

class DropDownCreator extends ComponentCreator{
    type = 'drop_down';
    constructor(id, nullable){
        super(id)
        this.nullable = (nullable === undefined) || nullable;
    }

    create_with = (props)=>{
        return React.createElement(DropDownInput, { ...props, inputId: this.id, nullable: this.nullable, defaultValue:this.defaultValue})
    }
}

class TablePickerCreator extends ComponentCreator{
    type = 'drop_down';
	constructor(id, tableCreator, transformer){
		super(id)
        this.tableCreator = tableCreator;
        this.transformer = transformer;
	}
    create_with = (props)=>{
        return React.createElement(TableSearchPicker , {tableCreator:this.tableCreator, ...props, inputId:this.id, transformer: this.transformer, defaultValue:this.defaultValue})
    }
}


class MultiPickerCreator extends ComponentCreator{
    type = "text_area"
    constructor(id, query, queryName, labelTransformer, queryVariablesTransformer, watchedStateIdentifierForSelectionClear, queryItemIterator) {
		super(id)
		this.query = query;
        this.queryName = queryName;
        this.labelTransformer = labelTransformer;
        this.queryVariablesTransformer = queryVariablesTransformer;
        this.watchedStateIdentifierForSelectionClear = watchedStateIdentifierForSelectionClear;
        this.queryItemIterator = queryItemIterator;
    }
    create_with = (props)=>{
        return React.createElement(MultiPicker,
                                   {...props,
                                    inputId:this.id,
                                    query: this.query,
                                    queryName:this.queryName,
                                    labelTransformer:this.labelTransformer,
                                    queryVariablesTransformer: this.queryVariablesTransformer,
                                    watchedStateIdentifierForSelectionClear: this.watchedStateIdentifierForSelectionClear,
                                    queryItemIterator : this.queryItemIterator,
                                    defaultValue: this.defaultValue,
                                   }
                                )
    }
}

class TableMultiPickerCreator2 extends ComponentCreator{
    type = "text_area"
    constructor(id, query, queryName, labelTransformer, queryVariablesTransformer, watchedStateIdentifierForSelectionClear) {
        this.id = id;
        this.query = query;
        this.queryName = queryName;
        this.labelTransformer = labelTransformer;
        this.queryVariablesTransformer = queryVariablesTransformer;
        this.watchedStateIdentifierForSelectionClear = watchedStateIdentifierForSelectionClear;
    }
    create_with = (props)=>{
        return React.createElement(TableMultiPicker2,
                                   {...props,
                                    inputId:this.id,
                                    query: this.query,
                                    queryName:this.queryName,
                                    labelTransformer:this.labelTransformer,
                                    queryVariablesTransformer: this.queryVariablesTransformer,
                                    watchedStateIdentifierForSelectionClear: this.watchedStateIdentifierForSelectionClear,
                                   }
                                )
    }
}

class TableMultiPickerCreator extends ComponentCreator{
    type = 'drop_down';
	constructor(id, query, queryName, tableMultiPickerColumnTransformers, queryVariablesTransformer, watchedStateIdentifierForSelectionClear){
        super(id);
		this.id = id;
		this.query = query;
        this.queryName = queryName;
        this.tableMultiPickerColumnTransformers = tableMultiPickerColumnTransformers;
        this.queryVariablesTransformer = queryVariablesTransformer;
        this.watchedStateIdentifierForSelectionClear = watchedStateIdentifierForSelectionClear;
	}
    create_with = (props)=>{
        return React.createElement(TableMultiPicker ,
                                    {...props,
                                    inputId:this.id,
                                    query: this.query,
                                    queryName:this.queryName,
                                    tableMultiPickerColumnTransformers:this.tableMultiPickerColumnTransformers,
                                    queryVariablesTransformer: this.queryVariablesTransformer,
                                    watchedStateIdentifierForSelectionClear: this.watchedStateIdentifierForSelectionClear,
                                    }
        )
    }
}


class InventoryAssociationHelperCreator extends ComponentCreator{
    type = 'text_area';
	constructor(id,query,labelMapper){
		super(id)
		this.query = query;
		this.labelMapper = labelMapper;
	}
    create_with = (props)=>{
        return React.createElement(InventoryAssociationHelper,
                                   {...props,
                                    inputId:this.id,
                                    query: this.query,
                                    labelMapper:this.labelMapper,
                                    defaultValue:this.defaultValue
                                   }
                                )
    }
}

class OptionsDropDownCreator extends ComponentCreator{
    type = 'drop_down';
	constructor(id,options){
        super(id)
		this.options = options;
	}
    create_with = (props)=>{
        const options = this.options;
        return React.createElement(OptionsDropDownInput, { options, ...props, inputId:this.id, defaultValue:this.defaultValue })
    }
}

class CheckboxListCreator extends ComponentCreator{
    type = 'drop_down';
	constructor(id,mapper,query,variables){
        super(id)
		this.mapper = mapper;
		this.query = query;
		this.variables = variables;
	}
    create_with = (props)=>{
        const mapper = this.mapper;
        const query = this.query;
        const variables = this.variables;
        return React.createElement(CheckboxListInput, { mapper, query, variables, ...props, inputId:this.id, defaultValue:this.defaultValue })
    }
}

class PeriodFilter {
    constructor(id) {
        this.id = id
    }

    filterCreator(props) {
        const defaultDate = new Date()

        return (
            <CustomTableCellInput key={'cell' + props.key} style={{fontSize: 16}}>
                <MonthPickerFilter
                    name={this.id}
                    style={{color: 'white'}}
                    defaultDate={defaultDate.getFullYear()}
                    onChangeDate={props.onChange}
                    filters={props.filters}
                    filterId={this.id}
                />
            </CustomTableCellInput>
        )
    }
}

class DateFilter{
    constructor(id){
        this.id = id
    }
    filterCreator(props){
        return(
              <CustomTableCellInput  key={"cell" + props.key}  style={{fontSize:16}}>
                <CustomOutlinedInput id="date"
                                     labelWidth={0}
                                     onChange={props.onChange}
                                     value={props.filters[this.id] || ""}
                                     type="date"
                                     name={this.id}
                                     style={{width:'100%'}}/>
              </CustomTableCellInput>

        )
    }
}
class DateCreator extends ComponentCreator{
    constructor(id, defaultDate=null){
        super(id, defaultDate)
    }

    type = 'date';

    create_with = (props)=>{
        return React.createElement(DateInput, { ...props, inputId:this.id, defaultValue:this.defaultValue || "" })
    }
}

class BooleanFilter{
    constructor(id, labelTrue="Active", labelFalse="Inactive"){
        this.id= id;
        this.labelTrue=labelTrue;
        this.labelFalse=labelFalse;
    }

    filterCreator(props){
       return (
            <SelectFilterComponent
                id={this.id}
                key={this.id}
                customKey={this.id}
                onChange={props.onChange}
                filters={props.filters}
                options={{filters:[{label:this.labelTrue,value:true},{label:this.labelFalse,value:false}],optionals:{all:"ALL"}}}
            />
       )
    }
}

class InvoicePaymentStateFilter{
    constructor(id){
        this.id= id;
    }

    filterCreator(props){
        return (
            <SelectFilterComponent
                id={this.id}
                key={this.id}
                customKey={this.id}
                onChange={props.onChange}
                filters={props.filters}
                options={{filters:[{label:"AVAILABLE",value:'0'},{label:"ASSIGNED",value:'1'},{label:"CLOSED",value:'2'}],optionals:{all:"ALL"}}}
            />
        )
     }
}


class InvoicePaymentGroupStateFilter{
    constructor(id){
        this.id= id;
    }

    filterCreator(props){
        return (
            <SelectFilterComponent
                id={this.id}
                key={this.id}
                customKey={this.id}
                onChange={props.onChange}
                filters={props.filters}
                options={{filters:[{label:"PENDING",value:0},{label:"PROCESSING",value:1},{label:"READY",value:2},{label:"CLOSED",value:3}],optionals:{all:"ALL"}}}
            />
        )
     }
}

class InvoiceStateFilter{
    constructor(id){
        this.id= id;
    }

    handleChange = (callback, e) => {
        if(e.target.value === "ALL"){
            e.target.value = ""
        }
        callback(e)
    }

    filterCreator(props){
       return (
           <React.Fragment key={"cell" + props.key}>
                  <CustomTableCellInput  key={"cell" + props.key}  style={{fontSize:16}}>
                   <Select name={this.id}
                           padding="checkbox"
                           onChange={(e) => this.handleChange(props.onChange, e)}
                           value={props.filters[this.id] !== undefined ? props.filters[this.id] : "ALL"}
                           style={{width:'100%',textAlign: 'center',fontWeight: 'bold'}}
                           input={<CustomOutlinedDropdownInput labelWidth={0} style={{paddingLeft:5}}/> }  >

                     <MenuItem value={"ALL"} key={"ALL"}> ALL </MenuItem>
                     <MenuItem value={"0"} key={0} > PENDING   </MenuItem>
                     <MenuItem value={"1"} key={1} > VALIDATED </MenuItem>
                     <MenuItem value={"2"} key={2} > ACCEPTED  </MenuItem>
                   </Select>
                  </CustomTableCellInput>
            </React.Fragment>
       )
    };
}

class ProcessControlStateFilter{
    constructor(id){
        this.id= id;
    }

    handleChange = (callback, e) => {
        if(e.target.value === "ALL"){
            e.target.value = ""
        }
        callback(e)
    }

    filterCreator(props){
       return (
           <React.Fragment key={"cell" + props.key}>
                  <CustomTableCellInput  key={"cell" + props.key}  style={{fontSize:16}}>
                   <Select name={this.id}
                           padding="checkbox"
                           onChange={(e) => this.handleChange(props.onChange, e)}
                           value={props.filters[this.id] !== undefined ? props.filters[this.id] : "ALL"}
                           style={{width:'100%',textAlign: 'center',fontWeight: 'bold'}}
                           input={<CustomOutlinedDropdownInput labelWidth={0} style={{paddingLeft:5}}/> }  >

                     <MenuItem value={"ALL"} key={"ALL"}> ALL </MenuItem>
                     <MenuItem value={'NOT_STARTED'} key={0} > NOT_STARTED   </MenuItem>
                     <MenuItem value={'IN_PROCESS'} key={1} > IN_PROCESS </MenuItem>
                     <MenuItem value={'FAILED'} key={2} > FAILED  </MenuItem>
                     <MenuItem value={'FINISHED'} key={3} > FINISHED  </MenuItem>
                   </Select>
                  </CustomTableCellInput>
        </React.Fragment>
       )
    };
}

function SelectFilterComponent(props) {
    const {id, filters, options, onChange, customKey} = props

    const handleChange = (callback, e) => {
        const filterAll = options.optionals.all || "ALL"
        // eslint-disable-next-line eqeqeq
        if(e.target.value == filterAll ){
            e.target.value = ""
        }
        callback(e)
    }

    return(
        <CustomTableCellInput  key={"cell" + customKey}  style={{fontSize:16}}>
            <Select
                name={id}
                padding="checkbox"
                onChange={(e) => handleChange(onChange, e)}
                // eslint-disable-next-line eqeqeq
                value={filters[id] != undefined ? filters[id] : (options.optionals.all || "ALL")}
                style={{width:'100%',textAlign: 'center',fontWeight: 'bold'}}
                input={<CustomOutlinedDropdownInput labelWidth={0} style={{color:"white",paddingLeft:5}}/> }
            >
                <MenuItem value={options.optionals.all || "ALL"} key={options.optionals.all || "ALL"}>
                    {options.optionals.all || "ALL"}
                </MenuItem>
                {options.filters.map(option =>
                    <MenuItem value={option.value} key={option.value}> {option.label} </MenuItem>
                )}
            </Select>
       </CustomTableCellInput>
    )
}

class UsersRoleFilter{
    constructor(id){
        this.id= id;
    }

    filterCreator(props){
        return (
            <SelectFilterComponent
                id={this.id}
                key={this.id}
                customKey={this.id}
                onChange={props.onChange}
                filters={props.filters}
                options={{filters:[{label:"Normal",value:"Normal"},{label:"Manager",value:"Manager"}],optionals:{all:"ALL"}}}
            />
        )
    }
}


class TraficClassFiler{
    constructor(id){
        this.id= id;
    }

    filterCreator(props){
        return (
            <SelectFilterComponent
                id={this.id}
                key={this.id}
                customKey={this.id}
                onChange={props.onChange}
                filters={props.filters}
                options={{filters:[{label:"Domestic",value:"Domestic"},{label:"Roaming",value:"Roaming"}],optionals:{all:"ALL"}}}
            />
        )
    }
}


class PeriosStateFilter {
    constructor(id) {
        this.id = id
    }

    filterCreator(props) {
        return (
            <SelectFilterComponent
                id={this.id}
                key={this.id}
                customKey={this.id}
                onChange={props.onChange}
                filters={props.filters}
                options={{filters:[{label:"Published",value:"1"},{label:"Unpublished",value:"0"}],optionals:{all:"ALL"}}}
            />
        )
     }
}

class BoolCreator extends ComponentCreator{
    type = 'bool';

    create_with = (props)=>{
        return React.createElement(SwitchInput, { ...props, inputId:this.id, defaultValue:this.defaultValue })
    }
}

class NullCreator extends ComponentCreator{
    type = 'null';

    filterCreator(props){
        return(
              <CustomTableCellInput  key={"cell" + props.key}  style={{fontSize:16}}>
                    <div style={{textAlign:"center"}}><span> - </span></div>
              </CustomTableCellInput>
        )
    };
    create_with = (props)=>{
        return null;
    }
}

// Validators ////////////////////////////////

class PasswordValidator{
	validate_with(value,id,label){
		let error = {};

                let count = 0;
                count += /[a-z]/.test(value) ? 1 : 0; // lower case alpha character
                count += /[A-Z]/.test(value) ? 1 : 0; // upper case alpha character
                count += /\d/.test(value)    ? 1 : 0; // numeric character
                count += /[@]/.test(value)   ? 1 : 0; // special character
                if(value.length < 1) {
                    error[id] = `${label} is requried`;
                } else if(value.length < 6) {
                    error[id] = `${label} is too short`;
                } else if(count < 2) {
                    error[id] = `${label} is too weak`;
                };

		return error;
	}
}

class RequiredValidator{
	constructor(l=1){
		this.minLength = l;
	}
	validate_with = (value,id,label) => {
		let error = {};
		if (_.isNull(value) || _.isUndefined(value) || value.length === 0){
            error[id] = `${label} is requried`;
		}else if(value.length < this.minLength){
            error[id] = `${label}  must have ${this.minLength} characters)`;
		};
		return error;
	}
}

class EmailValidator{
	validate_with(value,id,label){
		let error = {};
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!re.test(value)){
            error[id] = `${label} is not valid`;
		};
		return error;
	}
}


class NumberValidator{
	validate_with(value,id,label){
		let error = {};
        const re = /^-?\d+(\.\d+)?$/;
		if (!re.test(value)){
            error[id] = `${label} is not valid`;
		};
		return error;
	}
}

class InputDescription{
	nullValidator(){return false};
    constructor(component_creator, required, validator){
        this.component_creator = component_creator;
        this.validator = validator ? validator.validate_with : () => false;
        this.type = component_creator.type;
        this.required = required;
    }

    create_with(props){
        if (this.required){
            const required = label => <span><span style={{color:'red'}}>*</span> {label} </span> ;
            return this.component_creator.create_with({...props, required: required});
        }
        return this.component_creator.create_with(props);
    }

    validate_with(value,id,label) {
        return this.validator(value,id,label);
    }

    getFilter(props){
        return this.component_creator.filterCreator(props);
    }

    getDefaultValue() {
        return this.component_creator.getDefaultValue();
    }
}

class FilterDescription{
	nullFilter(){
        return <CustomTableCellInput  key={"cell" + props.key}/>
    };

    constructor(component_filter){
        this.component_filter = component_filter;
    }

    getFilter(props){
        if (! this.component_filter){
            return this.nullFilter(props);
        }
        return this.component_filter.filterCreator(props);
    }
}

export {
    UsersRoleFilter,
    TextAreaCreator,
    PasswordCreator,
    NameValueCreator,
    TextCreator,
    DropDownCreator,
    DropDownSearchCreator,
    DateCreator,
    BoolCreator,
    InputDescription,
    PasswordValidator,
    RequiredValidator,
    EmailValidator,
    NumberValidator,
    OptionsDropDownCreator,
    TablePickerCreator,
    NullCreator,
    FilterDescription,
    BooleanFilter,
    TextFilter,
    DateFilter,
    InvoiceStateFilter,
    ProcessControlStateFilter,
    InventoryAssociationHelperCreator,
    CheckboxListCreator,
    PeriosStateFilter,
    MultiPickerCreator,
    TableMultiPickerCreator,
    TableMultiPickerCreator2,
    PeriodFilter,
    InvoicePaymentStateFilter,
    NullTextFilter,
    InvoicePaymentGroupStateFilter,
    UploadCreator,
    TraficClassFiler,
    ModelCreator,
}
