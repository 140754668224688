import React from 'react'

import { useTranslation } from 'react-i18next'

import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import InventoryGlAccountFormCreator from '../../../tableDefinitions/Inventory/GlAccount/InventoryGlAccountFormCreator'
import { InventoryGlAccountTableCreator } from '../../../tableDefinitions/Inventory/GlAccount/InventoryGlAccountTableCreator'

const GlAccount = (props) => {
    const { t } = useTranslation('inventoryManagement')
    document.title = `${t('Gl Accounts')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Gl Accounts'}
            tableCreatorCreatorId={InventoryGlAccountTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={InventoryGlAccountFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default GlAccount