import { gql } from '@apollo/client'
import * as f from '../../../components/BPMTable/BPMInputs'
import {
    TableFieldDescription, 
    DataMapper, 
    NumberComponentCreator,
    DataTransformer, 
    ThousandSeparatorCreator,
    customPopUpContentDisplayStrategy,
} from  '../../../components/BPMTable/TableMapperCreator'
import InvoicePaymentPopupContent from '../../../pages/Invoice/InvoicePayment/InvoicePaymentPopupContent'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
query(
  $orderField:String,
  $first:Int,$last:Int,
  $after:String,
  $before:String,
  $invoiceId:String,
  $dueDate:String,
  $invoiceAccount:String,
  $invoicePaymentId:String,
  $state:String,
  $groupId:String,
  $date:String,
  $couponId:String,
  $value:String,
  $invoiceDate:String,
  $accountId:String,
  $accountDescription:String,
  $legalEntity:String,
  $vendor:String,
  $country:String,
){
  allInvoicePayments(
    orderField:$orderField,
    first:$first,
    last:$last,
    after:$after,
    before:$before,
    invoiceId:$invoiceId,
    dueDate:$dueDate,
    invoiceAccount:$invoiceAccount,
    invoicePaymentId:$invoicePaymentId,
    state:$state,
    groupId:$groupId,
    couponId:$couponId,
    date:$date,
    value:$value,
    invoiceDate:$invoiceDate,
    accountDescription:$accountDescription,
    accountId:$accountId,
    legalEntity:$legalEntity,
    vendor:$vendor,
    country:$country,
  ){
    edges{
      node{
        id
        state
        detail{
          date
          couponIdentifier
          value
        }
        group {
          id
        }
        invoice {
          identifier
          totalCost
          dueDate
          date
          account{
            identifier
            description
            legalEntity{
              name
            }
            provider{
              name
              country{
                name
              }
            }
            site{
              id
              name
              address
              state
              country{
                name
              }
            }
          }
          account {
            identifier
          }
          totalCost
          billingRequest {
            driver {
              vendor {
                name
              }
              currency {
                code
              }
            }
          }
        }
      }
    }
    count
    pageInfo {
      hasNextPage
      startCursor
      endCursor
    }
  }
}`;


const unitQuery = `
  invoicePayment(id:$id){
    id
    state
    detail{
      date
      couponIdentifier
      value
    }
    group {
      id
    }
    invoice {
      id
      identifier
      totalCost
      dueDate
      date
      account {
        identifier
        description
        legalEntity{
          name
        }
        provider{
          name
          country{
            name
          }
        }
        site{
          id
          name
          address
          state
          country{
            name
          }
        }
      }
      totalCost
      billingRequest {
        driver {
          vendor {
            name
          }
          currency {
            code
          }
        }
      }
    }
}
`;

const InvoicePaymentsTableCreatorQueries = {tableQuery, unitQuery}

const handleState = state => {
  const states = {
      'A_0' : 'AVAILABLE',
      'A_1' : 'ASSIGNED',
      'A_2' : 'CLOSED',
  }
  return states[state]
}

class InvoicePaymentsTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InvoicePaymentsTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, InvoicePaymentsTableCreatorQueries)
        tableCreator.setInitialSortField('dueDate', 'desc')
        tableCreator.setPopupContentStrategy(new customPopUpContentDisplayStrategy(InvoicePaymentPopupContent));
        tableCreator.addDescriptions ([
            new TableFieldDescription(
              'invoicePaymentId',
              new DataMapper("id"),
              new f.FilterDescription(new f.TextFilter("invoicePaymentId")),
              {label: 'Reference Identifier'}
            ),
            new TableFieldDescription(
              'invoiceId',
              new DataMapper("invoice.identifier"),
              new f.FilterDescription(new f.TextFilter("invoiceId")),
              {label: 'Identifier'}
            ),
            new TableFieldDescription(
              'invoiceAccount',
              new DataMapper("invoice.account.identifier"),
              new f.FilterDescription(new f.TextFilter("invoiceAccount")),
              {label: 'Account Identifier'}
            ),
            new TableFieldDescription(
              'invoiceTotalCost',
                new DataMapper("invoice.totalCost"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Total Cost', componentCreator:new ThousandSeparatorCreator(2)}
            ),
            new TableFieldDescription(
              'dueDate',
              new DataMapper("invoice.dueDate"),
              new f.FilterDescription(new f.PeriodFilter("dueDate")),
              {label: 'Due Date'}
            ),
            new TableFieldDescription(
              'groupId',
              new DataMapper("group.id"),
              new f.FilterDescription(new f.TextFilter("groupId")),
              {label: 'APGL ID'}
            ),
            new TableFieldDescription(
              'state',
              new DataMapper("state"),
              new f.FilterDescription(new f.InvoicePaymentStateFilter("state")),
              {label: 'State', transform:new DataTransformer(({state})=> handleState(state))}
            ),
            new TableFieldDescription(
              'accountId',
              new DataMapper("invoice.account.identifier"),
              new f.FilterDescription(new f.TextFilter("accountId")),
              {label: 'Account ID'}
            ),
            new TableFieldDescription(
              'accountDescription',
              new DataMapper("invoice.account.description"),
              new f.FilterDescription(new f.TextFilter("accountDescription")),
              {label: 'Account Description', onlyForm: true}
            ),
            new TableFieldDescription(
              'legalEntity',
              new DataMapper("invoice.account"),
              new f.FilterDescription(new f.TextFilter("legalEntity")),
              {label: 'Legal Entity', onlyForm: true, transform : new DataTransformer(({account})=>account ? (account.legalEntity ? account.legalEntity.name :null) : null)}
            ),
            new TableFieldDescription(
              'vendor',
              new DataMapper("invoice.account"),
              new f.FilterDescription(new f.TextFilter("vendor")),
              {label: 'Vendor', onlyForm: true , transform : new DataTransformer(({account})=>account ? (account.provider ? account.provider.name : null) : null)}
            ),
            new TableFieldDescription(
              'vendorCountry',
              new DataMapper("invoice.account"),
              new f.FilterDescription(new f.TextFilter("country")),
              {label: 'Country', onlyForm: true , transform : new DataTransformer(({account})=>account ? (account.provider ? account.provider.country.name : null) : null)}
            ),
            new TableFieldDescription(
              'invoiceDate',
              new DataMapper("invoice.date"),
              new f.FilterDescription(new f.PeriodFilter("invoiceDate")),
              {label: 'Invoice Date'}
            ),
            new TableFieldDescription(
              'date',
              new DataMapper("detail.date"),
              new f.FilterDescription(new f.PeriodFilter("date")),
              {label: 'Date'}
            ),
            new TableFieldDescription(
              'couponId',
              new DataMapper("detail.couponIdentifier"),
              new f.FilterDescription(new f.TextFilter("couponId")),
              {label: 'Coupon Identifier'}
            ),
            new TableFieldDescription(
              'value',
              new DataMapper("detail.value"),
              new f.FilterDescription(new f.NullCreator("value")),
              {label: 'Value' ,componentCreator:new NumberComponentCreator(this.parentProps.currentUserData.currency.symbol, 2)}
            ),
            new TableFieldDescription(
              'siteName',
              new DataMapper("invoice.account.site.name"),
              new f.FilterDescription(new f.NullCreator("name")),
              {label: 'Site Name' , hidenField: true}
            ),
            new TableFieldDescription(
              'siteAddress',
              new DataMapper("invoice.account.site.address"),
              new f.FilterDescription(new f.NullCreator("address")),
              {label: 'Site Address' , hidenField: true}
            ),
            new TableFieldDescription(
              'siteState',
              new DataMapper("invoice.account.site.state"),
              new f.FilterDescription(new f.NullCreator("state")),
              {label: 'Site State' , hidenField: true}
            ),
            new TableFieldDescription(
              'siteCountry',
              new DataMapper("invoice.account.site.country.name"),
              new f.FilterDescription(new f.NullCreator("name")),
              {label: 'Site Country' , hidenField: true}
            ),
            new TableFieldDescription(
              'vendor2',
              new DataMapper("invoice.billingRequest.driver.vendor.name"),
              new f.FilterDescription(new f.NullCreator("name")),
              {label: 'Vendor' , hidenField: true}
            ),
            new TableFieldDescription(
              'currency',
              new DataMapper("invoice.billingRequest.driver.currency.code"),
              new f.FilterDescription(new f.NullCreator("code")),
              {label: 'Currency' , hidenField: true}
            ),
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InvoicePaymentsTableCreator)

export default InvoicePaymentsTableCreator