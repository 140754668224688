/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    GeneralExcelDisplayCreator,
    GeneralChipComponentCreator,
    DataMapper, 
    DataTransformer,  
    periodToExcelTransformer, 
    StatusComponentCreator, 
    BooleanExcelDisplayCreator,
    TableFieldDescription 
} from '../../../components/BPMTable/TableMapperCreator'
import { InventoryClickStrategy } from '../../../components/BPMTable/TableMapperCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query AllInventories(
        $orderField:String,
        $first:Int,
        $last:Int,
        $firstName:String,
        $lastName:String,
        $after:String,
        $before:String,
        $status: Boolean,
        $domain:String,
        $validated: Boolean,
        $serviceNumber: String,
        $serviceType: String,
        $devices: String,
        $customAttr1: String,
        $costCenters: String,
        $glAccounts: String,
        $customAttr2: String,
        $customAttr3: String,
        $sites: String,
        $users: String,
        $updatedAt: String,
        $serialNumber:String,
        $serialNumberType:String,
        $startDate:String,
        $endDate:String,
    ) {
        allInventories(
            firstName:$firstName,
            lastName:$lastName,
            orderField:$orderField,
            first:$first,
            last:$last,
            domain:$domain,
            after:$after,
            customAttr1:$customAttr1,
            customAttr2:$customAttr2,
            customAttr3:$customAttr3,
            before:$before,
            status: $status,
            validated: $validated,
            serviceType: $serviceType,
            devices: $devices,
            glAccounts: $glAccounts,
            sites: $sites,
            users: $users,
            costCenters: $costCenters,
            serviceNumber: $serviceNumber,
            updatedAt: $updatedAt,
            serialNumber:$serialNumber,
            serialNumberType:$serialNumberType,
            startDate:$startDate,
            endDate:$endDate,
        ) {
            edges{
                node {
                    id
                    serviceNumber
                    serviceType {
                        id
                        technology {
                            name
                            id
                        }
                        name
                    }
                    status
                    validated
                    updatedAt
                    customAttr1
                    customAttr2
                    customAttr3
                    domain {
                        id
                        name
                    }
                    users{
                        id
                        user {
                            id
                            enterpriseId
                            firstName
                            lastName
                        }
                        date
                    }
                    sites {
                        id
                        site {
                            id
                            name
                        }
                    }
                    devices {
                        id
                        device {
                            serialNumber
                            serialNumberType{
                                name
                            }
                            id
                            model {
                                name
                            }
                        }
                    }
                    glAccounts {
                        id
                        items {
                            id
                            glAccount {
                                id
                                displayName
                            }
                            percentage
                        }
                    }
                    costCenters{
                        id
                        items {
                            id
                            costCenter {
                                id
                                enterpriseId
                                displayNameWithParents
                                displayName
                            }
                            percentage
                        }
                    }
                    associationsLastDate
                    startDate
                    endDate
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation editInventoryMutation(
        $id: String!,
        $users: String,
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $serviceNumber: String,
        $serviceType: String,
        $costCenters: String,
        $sites: String,
        $status: Boolean,
        $validated: Boolean,
        $glAccounts: String,
        $devices: String,
        $domain: String,
        $associationsLastDate: Date,
        $excludedFromOptimizations: Boolean,
        $excludedReason: String,
        $startDate: Date,
        $endDate: Date,
) {
        editInventory(
            id: $id,
            users:$users,
            customAttr1: $customAttr1,
            customAttr2: $customAttr2,
            customAttr3: $customAttr3,
            serviceNumber: $serviceNumber,
            serviceType: $serviceType,
            costCenters: $costCenters,
            status: $status,
            validated: $validated,
            sites: $sites,
            glAccounts: $glAccounts,
            devices: $devices,
            domain:$domain,
            associationsLastDate: $associationsLastDate,
            excludedFromOptimizations: $excludedFromOptimizations,
            excludedReason: $excludedReason,
            startDate: $startDate,
            endDate: $endDate,
        ) {
            inventory {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createInventoryMutation(
        $users: String,
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $serviceNumber: String,
        $serviceType: String,
        $costCenters: String,
        $sites: String,
        $status: Boolean,
        $validated: Boolean,
        $glAccounts: String,
        $devices: String,
        $domain: String,
        $associationsLastDate: Date,
        $excludedFromOptimizations: Boolean,
        $excludedReason: String,
        $startDate: Date,
        $endDate: Date,
    ) {
        createInventory(
            users:$users,
            customAttr1: $customAttr1,
            customAttr2: $customAttr2,
            customAttr3: $customAttr3,
            serviceNumber: $serviceNumber,
            serviceType: $serviceType,
            costCenters: $costCenters,
            status: $status,
            validated: $validated,
            sites: $sites,
            glAccounts: $glAccounts,
            devices: $devices,
            domain: $domain,
            associationsLastDate: $associationsLastDate,
            excludedFromOptimizations: $excludedFromOptimizations,
            excludedReason: $excludedReason,
            startDate: $startDate,
            endDate: $endDate,
        ) {
            inventory {
                id
            }
        }
    }
`

const deleteMutator = gql`
    mutation removeInventoryMutation($ids: [String!], $allRecords: Boolean, $filters: String) {
        removeInventory(ids: $ids, allRecords: $allRecords, filters: $filters) {
            inventory {
                id
            }
        }
    }
`

const unitQuery = `
    inventory(id: $id) {
        id
        serviceNumber
        domain {
            name
            id
        }
        serviceType {
            id
            name
            technology {
                id
                name
            }
        }
        status
        validated
        updatedAt
        devices {
            device {
                model {
                    name
                    brand {
                        name
                    }
                    serviceType {
                        name
                    }
                }
            }
        }
        sites {
            site {
                id
                name
                address
                postalCode
                enterpriseId
            }
        }
        users {
            id
            user {
                id
                enterpriseId
                firstName
                lastName
                email
            }
            date
        }
        customAttr1
        customAttr2
        customAttr3
        devices {
            id
            device {
                id
                model {
                    name
                }
                serialNumber
            }
        }
        glAccounts{
            id
            items {
                id
                glAccount {
                    id
                    displayName
                }
                percentage
            }
        }
        costCenters{
            id
            items {
                id
                costCenter {
                    id
                    enterpriseId
                    displayNameWithParents
                }
                percentage
            }
        }
        associationsLastDate
        excludedFromOptimizations
        excludedReason
        startDate
        endDate
    }
`

const InventoryQueries = { tableQuery, editMutator, newMutator, deleteMutator, unitQuery }

class InventoryTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InventoryTableCreator"
    }

    getFieldDescriptions = () => {
        return [
            new TableFieldDescription(
                'serviceNumber',
                new DataMapper("serviceNumber"),
                new f.FilterDescription(new f.TextFilter("serviceNumber")),
                {label: 'Service Number'}
            ),
            new TableFieldDescription(
                'costCenters',
                new DataMapper("costCenters"),
                new f.FilterDescription(new f.TextFilter("costCenters")),
                {
                    label: "Cost Centers",
                    excelDisplay : new GeneralExcelDisplayCreator('Cost Center', 
                        [{label: 'Code', path: 'costCenter.enterpriseId'}, 
                        {label: 'Name', path: 'costCenter.displayName', defaultValue: 'Not Assigned'}, 
                        {label: '%', path: 'percentage', defaultValue: 100.00}], 3),
                    componentCreator: new GeneralChipComponentCreator(['costCenter.displayNameWithParents'], null, 'Not Assigned'),
                    transform: new DataTransformer(({costCenters}) => costCenters ? costCenters.items : []),
                }
            ),
            new TableFieldDescription(
                'serviceType',
                new DataMapper("serviceType.name"),
                new f.FilterDescription(new f.TextFilter("serviceType")),
                {label: 'Service Type'}
            ),
            new TableFieldDescription(
                'devices',
                new DataMapper("devices.device.model.name"),
                new f.FilterDescription(new f.TextFilter("devices")),
                {label: 'Device Model'}
            ),    new TableFieldDescription(
                'serialNumber',
                new DataMapper("devices.device.serialNumber"),
                new f.FilterDescription(new f.TextFilter("serialNumber")),
                {label: 'Serial Number'}
            ),    new TableFieldDescription(
                'serialNumberType',
                new DataMapper("devices.device.serialNumberType.name"),
                new f.FilterDescription(new f.TextFilter("serialNumberType")),
                {label: 'Serial Number Type'}
            ),
            new TableFieldDescription(
                'users',
                new DataMapper("users.user.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("users")),
                {label: 'User ID'}
            ),
            new TableFieldDescription(
                'firstName',
                new DataMapper("users.user.firstName"),
                new f.FilterDescription(new f.TextFilter("firstName")),
                {label: 'First Name'}
            ),
            new TableFieldDescription(
                'lastName',
                new DataMapper("users.user.lastName"),
                new f.FilterDescription(new f.TextFilter("lastName")),
                {label: 'Last Name'}
            ),
            new TableFieldDescription(
                'sites',
                new DataMapper("sites.site.name"),
                new f.FilterDescription(new f.TextFilter("sites")),
                {label: 'Site', onlyForm:true}
            ),
                new TableFieldDescription(
                'glAccounts',
                new DataMapper("glAccounts"),
                new f.FilterDescription(new f.TextFilter("glAccounts")),
                {
                    label: 'GL Accounts',
                    excelTransform :new DataTransformer(({glAccounts}) => glAccounts && glAccounts.items[0] && glAccounts.items[0].glAccount ? glAccounts.items[0].glAccount.displayName : "Not Assigned"),
                    componentCreator: new GeneralChipComponentCreator(['glAccount.displayName'], null, 'Not Assigned', false),
                    transform: new DataTransformer(({glAccounts})=>glAccounts ?  glAccounts.items : []),
                    onlyForm:true
                }
            ),
            new TableFieldDescription(
                'associationsLastDate',
                    new DataMapper("associationsLastDate"),
                    new f.FilterDescription(new f.NullCreator()),
                {label: 'Association Last Date', transform: new DataTransformer(data=> data.associationsLastDate ? data.associationsLastDate.split('T')[0] : ""), excelTransform:new DataTransformer(({associationsLastDate})=> associationsLastDate ? periodToExcelTransformer(associationsLastDate) : null)}
            ),
            new TableFieldDescription(
                'updatedAt',
                new DataMapper("updatedAt"),
                new f.FilterDescription(new f.TextFilter("updatedAt")),
                {label: 'Updated At', transform: new DataTransformer(({updatedAt}) => updatedAt? updatedAt.split('T')[0] : null), onlyForm:true}
            ),
            new TableFieldDescription(
                'domain',
                new DataMapper("domain.name"),
                new f.FilterDescription(new f.TextFilter("domain")),
                {label: 'Domain'}
            ),
            new TableFieldDescription(
                'status',
                new DataMapper("status"),
                new f.FilterDescription(new f.BooleanFilter("status")),
                {label: 'Status', componentCreator:new StatusComponentCreator(), excelDisplay : new BooleanExcelDisplayCreator()}
            ),
            new TableFieldDescription(
                'validated',
                new DataMapper("validated"),
                new f.FilterDescription(new f.BooleanFilter("validated", 'Yes', 'No')),
                {label: 'Validated', componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator("Yes", "No")}
            ),
            new TableFieldDescription(
                'customAttr1',
                new DataMapper("customAttr1"),
                new f.FilterDescription(new f.TextFilter("customAttr1")),
                {label: 'Custom Attribute 1', onlyForm:true}
            ),
            new TableFieldDescription(
                'customAttr2',
                new DataMapper("customAttr2"),
                new f.FilterDescription(new f.TextFilter("customAttr2")),
                {label: 'Custom Attribute 2', onlyForm:true}
            ),
            new TableFieldDescription(
                'customAttr3',
                new DataMapper("customAttr3"),
                new f.FilterDescription(new f.TextFilter("customAttr3")),
                {label: 'Custom Attribute 3', onlyForm:true}
            ),
            new TableFieldDescription(
                'startDate',
                new DataMapper("startDate"),
                new f.FilterDescription(new f.PeriodFilter("startDate")),
                {label: 'Start Date', transform: new DataTransformer(data => data.startDate ? data.startDate.split('T')[0]: null), excelTransform:new DataTransformer(({startDate})=> startDate ? periodToExcelTransformer(startDate) : null)}
            ),
            new TableFieldDescription(
                'endDate',
                new DataMapper("endDate"),
                new f.FilterDescription(new f.PeriodFilter("endDate")),
                {label: 'End Date', transform: new DataTransformer(data => data.endDate ? data.endDate.split('T')[0]: null), excelTransform:new DataTransformer(({endDate})=> endDate ? periodToExcelTransformer(endDate) : null)}
            ),
        ]
    }

    getTableCreator = (tableName) => {
        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, InventoryQueries, new InventoryClickStrategy())
        tableCreator.addDescriptions(this.getFieldDescriptions())

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InventoryTableCreator)

export {
    InventoryTableCreator,
    InventoryQueries
};