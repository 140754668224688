import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    FormFieldDescription,
    FormSection,
    DataTransformer
} from '../../../components/BPMTable/TableMapperCreator'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import { chargeGroupTableCreatorQueries } from './ChargeGroupTableCreator'


const allChargeGroups = `
    allChargeGroup {
        edges {
            node {
                id
                name
            }
        }
    }
`

class ChargeGroupFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "ChargeGroupFormCreator"
    }

   getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Charge Group', chargeGroupTableCreatorQueries)
        const generalSection = new FormSection('General Seccion', 1)
        formCreator.addInputs([
            new FormFieldDescription(
                'name',
                new f.InputDescription(new f.TextCreator('name'), true, new f.RequiredValidator(1)),
                generalSection,
                2,
                {label: 'Name'}
            ),
            new FormFieldDescription(
                'parent',
                new f.InputDescription(new f.DropDownCreator('parent'), false),
                generalSection,
                3,
                {label: 'Parent', queryAllDropdownOptions: allChargeGroups, transform: new DataTransformer(data => data.name)}
            )
        ])

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(ChargeGroupFormCreator)

export default ChargeGroupFormCreator;