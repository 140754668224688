import { gql } from '@apollo/client'

const massiveLoadTemplateMutation = gql`
mutation ExecuteMassiveLoadTemplateMutation(
    $model:String!,
    $invoiceId:Int,
) {
    executeMassiveLoadTemplate (
        model:$model,
        invoiceId:$invoiceId,
    )
    {
        massiveLoadTemplate{
            id
            user {
                id
                firstName
                lastName
            }
            createdAt
            state
            message
            modelName
        }
    }
}
`

export default massiveLoadTemplateMutation;