import { FormCreator } from "../TableMapperCreator"

export default class FormCreatorCreator {
    constructor() {

    }

    createFormCreator = (formCreatorId, name, queries, onConfirmFormStrategy, onCancelFormStrategy) => {
        return new FormCreator(formCreatorId, name, queries, onConfirmFormStrategy, onCancelFormStrategy)
    }
}