import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Route, Switch } from 'react-router-dom'
import ReportsFilter from '../../components/ReportsFilter/ReportsFilter'
import Schedule from './schedule'
import Results from './results'

function TableSetup(props) {
    const [changeTabs, SetTabs] = useState(false)

    const handleChangeButtons = (openTab) => {
        SetTabs(openTab)
    }

    var infoLegend = ""

    if (changeTabs) {
        document.title = "Results - BubbleBPM"
        infoLegend = props.t(`SBO report`)
    } else {
        document.title = "Schedule - BubbleBPM"
        infoLegend = props.t(`Creates SBO from applied filters`)
    }

    const buttonsData = [
        {
            label: props.t("Schedule"),
            color: changeTabs ? "gray" : "#55758C",
            link: "/consolidatedSBO/schedule",
            variant: changeTabs ? "outlined" : "contained"
        },
        {
            label: props.t("Results"),
            color: !changeTabs ? "gray" : "#55758C",
            link: "/consolidatedSBO/results",
            variant: !changeTabs ? "outlined" : "contained"
        }
    ]

    return (
        <>
            <ReportsFilter
                buttons={buttonsData}
                info={{ label: infoLegend }}
            />

            <Switch>
                <Route exact path="/consolidatedSBO/schedule" component={() => {
                    return (
                        <Schedule
                            t={props.t}
                            onSelected={handleChangeButtons}
                        />
                    )
                }}
                />
                <Route exact path="/consolidatedSBO/results" component={() => {
                    return (
                        <Results
                            t={props.t}
                            onSelected={handleChangeButtons}
                        />
                    )
                }}
                />
            </Switch>
        </>
    )
}

export function SetUpData(props) {
    const { t } = props

    return (
            <div /*style={{height:'100%',width:'100%',padding:'20px 10px 20px 15px'}}*/ id="app">
                <TableSetup
                    t={t}
                    url={props.match.url}
                />
            </div>
    )
}

export default withTranslation("ConsolidatedSBO")(SetUpData)