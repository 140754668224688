/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React from "react";
import gql from "graphql-tag";
import _ from 'lodash';

export default class FormManager extends React.Component{

    constructor(formCreatorId, formName, unitQuery, unitQueryName, newMutator, editMutator, inputDescriptions, onConfirmFormStrategy, onCancelFormStrategy){
        super();
        this.formCreatorId = formCreatorId;
        this.tableName = formName;
        this.unitQuery = unitQuery;
        this.unitQueryName = unitQueryName;
        this.newMutator = newMutator;
        this.editMutator = editMutator;
        this.inputDescriptions = inputDescriptions;
        this.onConfirmFormStrategy = onConfirmFormStrategy; 
        this.onCancelFormStrategy = onCancelFormStrategy; 

    }

    onFormConfirmedStrategy(component, path, response){
        return this.onConfirmFormStrategy.doFor(component,path, response)
    }

    onFormCanceledStrategy(component, path){
        return this.onCancelFormStrategy.doFor(component,path)
    }

    getMutation(mutate){
        const mutations = {
            'edit' : this.editMutator,
            'new'  : this.newMutator
        }
        return mutations[mutate]
    }

    mutationQuery(method){
        let dropdownQueries = '';
        this.inputDescriptions.filter(field => field.queryAllDropdownOptions).map(
            field => {dropdownQueries += '\n' + field.queryAllDropdownOptions}
        )
        const nullQuery = `
            __type(name:""){
                name
            }
        `;

        const methods = {
            'new' : `query New{
                        ${dropdownQueries}
                        ${nullQuery}
                    }`,
            'edit': `query Edit($id: String!){
                        ${this.unitQuery || '' }
                        ${dropdownQueries}
                        ${nullQuery}
                    }`
        }
        return(
            gql`${methods[method]}`
        );
    }

    mutationMapper(data){
        const formData = {}
        const displayData = {}
        const mappedData= {};
        const queryData = data[this.unitQueryName] || {};
        const dropDownFields = this.inputDescriptions.filter(f => f.queryAllDropdownOptions);
        dropDownFields.map( field =>{
            if(data[field.queryName].hasOwnProperty("edges")){
                data[field.queryName].edges.filter(d => d != undefined)
                .map(dropDownData =>{
                    dropDownData    = dropDownData.node;
                    const input     = {id : dropDownData.id};
                    input[field.id] = field.transform(dropDownData);
                    formData[field.id] = [].concat(formData[field.id],input);
                } );
            }else{
                data[field.queryName].filter(d => d != undefined)
                .map(dropDownData =>{
                    const input     = {id : dropDownData.id};
                    input[field.id] = field.transform(dropDownData);
                    formData[field.id] = [].concat(formData[field.id],input);
                } );
            }
            // (avoid empty dropdown data error)
            if (formData[field.id]){
                formData[field.id].slice(1)};
        })
        mappedData['id'] = queryData['id']
        this.inputDescriptions.map(field =>{
            var fieldData = field.mapData(queryData);
            if (fieldData !== undefined) {
                mappedData[field.id] = field.stateTransformer(fieldData);
                displayData[field.id] = field.transform(fieldData);
            }
        })

        return { mappedData, formData, displayData }
    }

    prepareData(data) {
        var variables = {};

        for (let index in this.inputDescriptions){
            var inputDescription = this.inputDescriptions[index]
            var variable = data[inputDescription.id]
            variables[inputDescription.id] = variable !== undefined ? inputDescription.prepareData(variable) : null
        };

        variables["id"] = data.id;

        return variables;
    }

    getInputs(properties){
        // returns list of input functions
        const inputs = [];
        this.inputDescriptions.map(field =>{
            const input = field.inputDescription.create_with(
                {
                    ...properties,
                    id: field.id,
                    label: field.label,
                    key: field.id,
                    section: field.section,
                    priorityOnSection: field.priorityOnSection,
                    readOnly:field.readOnly
                }
            );
            input ? inputs.push(input) : null;
        });

        const uniqueSections = _.uniq(_.map(this.inputDescriptions, input => input.section.name));
        const sectionObjectsArray = uniqueSections.map(
            section => (
                {
                    [section]: _.sortBy(
                        _.filter(inputs, input => input.props.section.name === section),
                        input => input.props.priorityOnSection
                    )
                }
            )
        );
        return sectionObjectsArray;
    }

    getSections() {
        const orderedInputs = _.sortBy(this.inputDescriptions, field => field.section.priority);
        return _.uniq(_.map(orderedInputs, field => field.section.name, field => field.section.priority));
    }

    errorCheck(values){
	    let errors = {};
	    this.inputDescriptions.map( field =>{
		   const value = values[field.id] || '';
		   Object.assign(errors, field.inputDescription.validate_with(value, field.id, field.label));
	    })
	    return errors;
    }

}