import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataTransformer, 
    FormFieldDescription, 
    FormSection, 
} from '../../../components/BPMTable/TableMapperCreator'
import UserMinimalTable from '../../../pages/Inventory/User/MinimalTable'
import GlAccountMinimalTable from '../../../pages/Inventory/glAccount/GlAccountMinimalTable'
import { InventoryGlAccountTableCreatorQueries } from './InventoryGlAccountTableCreator'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'

const allDomain = `
    availableDomains {
        name
        id
    }
`

class InventoryGlAccountFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "InventoryGlAccountFormCreator"
    }

    getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Gl Account', InventoryGlAccountTableCreatorQueries)
        const generalSection = new FormSection('General Seccion', 1);
        const UserTablePicker = new UserMinimalTable({first: 15, orderDirection: "desc", orderField: "isActive", isActive: true, role: "Manager"}) 
        const GlAccountTablePicker = new GlAccountMinimalTable({first: 15, orderDirection: "desc", orderField: "isActive", isActive: true})
        const managerSection = new FormSection('Manager', 2);
        const parentSection = new FormSection('Parent', 3);

        formCreator.addInputs([
            new FormFieldDescription(
                'parent',
                new f.InputDescription(new f.ModelCreator('parent', GlAccountTablePicker), false),
                parentSection,
                4,
                {label: 'Parent',  transform: new DataTransformer(data => data, data=>data)}
            ),
            new FormFieldDescription(
                'name',
                new f.InputDescription(new f.TextCreator('name'), true, new f.RequiredValidator(1)),
                generalSection,
                1,
                {label: 'Name'}
            ),
            new FormFieldDescription(
                'manager',
                new f.InputDescription(new f.ModelCreator('manager', UserTablePicker), false),
                managerSection,
                3,
                {label: 'Manager', transform: new DataTransformer(data=> data, data=> data)}
            ),
            new FormFieldDescription(
                'type',
                new f.InputDescription(new f.TextCreator('type'), false),
                generalSection,
                2,
                {label: 'Type'}
            ),
            new FormFieldDescription(
                'isActive',
                new f.InputDescription(new f.BoolCreator('isActive', false), false),
                generalSection,
                6,
                {label: 'Status'}
            ),
            new FormFieldDescription(
                'domain',
                new f.InputDescription(new f.DropDownCreator('domain'), false),
                generalSection,
                6,
                {label: 'Domain', queryAllDropdownOptions: allDomain, transform: new DataTransformer(data => data.name)}
            ),
            new FormFieldDescription(
                'customAttr1',
                new f.InputDescription(new f.TextAreaCreator('customAttr1'), false),
                generalSection,
                7,
                {label: 'Custom Attribute 1'}
            ),
            new FormFieldDescription(
                'customAttr2',
                new f.InputDescription(new f.TextAreaCreator('customAttr2'), false),
                generalSection,
                8,
                {label: 'Custom Attribute 2'}
            ),
            new FormFieldDescription(
                'customAttr3',
                new f.InputDescription(new f.TextAreaCreator('customAttr3'), false),
                generalSection,
                9,
                {label: 'Custom Attribute 3'}
            )
        ])

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(InventoryGlAccountFormCreator)

export default InventoryGlAccountFormCreator;