import React from 'react'

import { gql } from '@apollo/client'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { AddBPMApplicationMessages } from '../../../redux/reducers/BPMReducer'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/styles';
import { Button, LinearProgress, Paper, Typography } from '@material-ui/core';
import DropDownInput from '../../../components/Inputs/DropDownInput'
import FileInput from '../../../components/Inputs/FileInput'
import { AxiosContext } from '../../../contexts/Axios'

const ColorLinearProgress = withStyles({
    colorPrimary: {
        backgroundColor: '#a7cbc7',
    },
    barColorPrimary: {
        backgroundColor: '#075e54',
    }
})(LinearProgress)  

const DRIVER_FILE_QUERY = gql`
    query($driver: String) {
        allDriverFiles(driver: $driver, orderField:"position") {
            edges {
                node {
                    id
                    name
                    extension
                    position
                }
            }
        }
    }
`

const DRIVER_QUERY = gql`
    query($vendor: String) {
        availableCompanyDrivers(vendor:$vendor) {
            edges{
                node{
                    id
                    driver {
                        id
                        name
                    }
                }
            }

        }
    }
`

const PROVIDER_QUERY = gql`
    query($country: String) {
        availableVendors(country: $country) {
            edges{
                node{
                    id
                    name        
                }
            }
        }
    }
`

const COUNTRY_QUERY = gql`
    query {
        availableCountries {
            edges{
                node{
                    id
                    name
                }
            }
        }
    }
`

class UploadBilling extends React.Component {
    static contextType = AxiosContext

    constructor(props) {
        super(props)
        this.state = {
            isUploading : false,
            uploadProgress : 0,
            countryOptions: [],
            selectedCountry: null,
            providerOptions: [],
            selectedProvider: null,
            driverOptions: [],
            selectedDriver: null,
            fileOptions: [],
            files: {},
        }
    }

    componentDidMount() {
        this.setState({countryOptions : this.getCountryOptions()})
    }

    getCountryOptions = () => {
        this.props.client.query({
            query:COUNTRY_QUERY
        }).then(({data}) =>{
            this.setState({countryOptions : data.availableCountries.edges})
        }).catch( err => {
            console.log(err);
        })
    }

    getProviderOptions = (value) => {
        this.props.client.query({
            query:PROVIDER_QUERY,
            variables:{country:value}
        }).then(({data}) =>{
            this.setState({providerOptions : data.availableVendors.edges})
        }).catch( err => {
            console.log(err);
        })
    }

    getDriverOptions = (value) => {
        this.props.client.query({
            query:DRIVER_QUERY,
            variables:{vendor:value}
        }).then(({data}) =>{
            this.setState({driverOptions : data.availableCompanyDrivers.edges})
        }).catch( err => {
            console.log(err);
        })
    }

    getDriverFileOptions = (value) =>{
        this.props.client.query({
            query:DRIVER_FILE_QUERY,
            variables:{driver:value}
        }).then(({data}) =>{
            this.setState({fileOptions : data.allDriverFiles.edges})
        }).catch( err => {
            console.log(err);
        })
    }

    setCountry = value => {
        this.getProviderOptions(value)
        this.setState({
            selectedCountry : value,
            providerOptions: [],
            selectedProvider: null,
            driverOptions: [],
            selectedDriver: null,
            fileOptions: [],
            files: {},
        })
    }

    setProvider = value => {
        this.getDriverOptions(value)
        this.setState({
            selectedProvider : value,
            driverOptions: [],
            selectedDriver: null,
            fileOptions: [],
            files: {},
        })
    }

    setDriver = value => {
        this.getDriverFileOptions(value)
        this.setState({ 
            selectedDriver : value,
            fileOptions: [],
            files: {},
        })
    }

    setFiles = (event) => {
        const id = event.target.id;
        const file = event.target.files[0];
        // eslint-disable-next-line no-unused-vars
        const extension = event.target.name;
        this.setState(state => {
            // eslint-disable-next-line rest-spread-spacing
            const newFiles = { ... state.files};
            newFiles[id] = file ;
            return(
              { files: newFiles }
            )
        });
    }

    upload = () => {
        this.setState({isUploading: true});
        const config = {
            onUploadProgress: (progressEvent) => {
                this.setState({uploadProgress : Math.round((progressEvent.loaded * 100) / progressEvent.total)});
            }
        };
        const data = new FormData();
        data.append('driver', this.state.selectedDriver)
        const filesDesc = [];
        // eslint-disable-next-line array-callback-return
        Object.keys(this.state.files).map(key => {
            const file = this.state.files[key];
            filesDesc.push({id: key,  fileName: file.name})
            data.append(key, file)
        })
        data.append('filesDesc', JSON.stringify(filesDesc))
        this.context.axiosClient.post(
            '/billing/upload/',
            data,
            config
        ).then(res => {
            this.props.AddBPMApplicationMessages({
                text: this.props.t('Billing has been Uploaded, Redirecting...'), 
                variant: 'success',
            })
            setTimeout(() => {
                this.props.history.push('/processControl/');
            }, 4000);
        }).catch(err => {
            console.log(err);
            this.props.AddBPMApplicationMessages({
                text: this.props.t('Error uploading'), 
                variant: 'error',
            })
        })
    }

    render() {
        document.title = `Upload Billing - BubbleBPM`
        const { t } = this.props
        const { 
            countryOptions,
            selectedCountry,
            providerOptions,
            selectedProvider,
            uploadProgress,
            driverOptions,
            isUploading,
            selectedDriver,
            fileOptions,
            files 
        } = this.state
        // eslint-disable-next-line eqeqeq
        const allFilesSelected = fileOptions.length > 0 && Object.values(files).length == fileOptions.length

        return (
            <>
                <Typography variant="h6" >Upload Billing</Typography>
                <Paper style={{padding: 15, marginTop:15, width:"fit-content", marginLeft:10}}>
                    <DropDownInput 
                        list={countryOptions}
                        value={selectedCountry}
                        label={t("Select Country")}
                        styles={{padding:10}}
                        valueMapping={(item) => item.node.id}
                        labelMapping={(item) => item.node.name}
                        onChange={this.setCountry} 
                    />

                    { selectedCountry &&
                        <DropDownInput 
                            list={providerOptions}
                            value={selectedProvider}
                            label={t("Select Provider")}
                            styles={{padding:10}}
                            valueMapping={(item) => item.node.id}
                            labelMapping={(item) => item.node.name}
                            onChange={this.setProvider} 
                        />
                    }
                    { selectedProvider &&
                        <DropDownInput 
                            list={driverOptions}
                            value={selectedDriver}
                            label={t("Select Driver")}
                            styles={{padding:10}}
                            valueMapping={(item) => item.node.driver.id}
                            labelMapping={(item) => item.node.driver.name}
                            onChange={this.setDriver}
                        />
                    }
                    { selectedDriver &&
                        <div>
                            {fileOptions.map(file => (
                                <FileInput 
                                    label={file.node.name}
                                    id={file.node.id}
                                    extension={file.node.extension}
                                    key={"file"+file.node.id}
                                    onChangeFunc={this.setFiles}
                                    styles={{padding:5, width:265 }}
                                />
                            ))}

                            <Button 
                                variant='contained'
                                size='small'
                                style={{width:'100%', marginTop:10}}
                                color='primary'
                                onClick={this.upload}
                                disabled={!allFilesSelected || isUploading} >
                                {t('Upload')}
                            </Button>

                            {isUploading &&
                                <div style={{paddingTop: 5}}>
                                    <ColorLinearProgress style={{marginBottom: 5}} variant="determinate" value={uploadProgress} />
                                    <Typography style={{textAlign: 'center'}}>
                                        {`${uploadProgress}%`}
                                    </Typography>
                                </div>
                            }
                        </div>
                    }
                </Paper>
            </>
        )
    }
}

const mapDispatchToProps = {
    AddBPMApplicationMessages,
};

export default withTranslation('uploadBilling')(withRouter(connect(null, mapDispatchToProps)(UploadBilling)));
