import { gql } from '@apollo/client'
import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    TableFieldDescription,
    HideDeletionDisplayStrategy,
    DefaultClickStrategy, 
} from '../../../components/BPMTable/TableMapperCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
const tableQuery = gql`
    query AllDestinations(
        $orderField: String,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $name: String,
    ) {
        allDestinations(
            orderField: $orderField,
            first: $first,
            last: $last,
            after: $after,
            before: $before,
            name: $name,
        ) {
            edges {
                node {
                    id
                    name
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation($name: String!, $id: String!) {
        editDestination(name: $name, id:$id) {
            destination {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation($name: String!) {
        createDestination(name: $name) {
            destination {
                id
            }
        }
    }
`

const deleteMutator = gql`
    mutation removeDeviceMutation($ids: [String!], $allRecords: Boolean, $filters: String) {
        removeDevice(ids: $ids, allRecords: $allRecords, filters: $filters) {
            destintion {
                id
            }
        }
    }
`

const unitQuery = `
    destination(id: $id) {
        id
        name
    }
`

const DestinationTableCreatorQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class DestinationTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "DestinationTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, DestinationTableCreatorQueries, new DefaultClickStrategy(), new HideDeletionDisplayStrategy())
        tableCreator.setInitialSortField('name', 'desc')
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'name',
                new DataMapper("name"),
                new f.FilterDescription(new f.TextFilter("name")),
                {label: 'Name'}
            )
        ])

        return tableCreator
    }
    
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(DestinationTableCreator)

export {
    DestinationTableCreator,
    DestinationTableCreatorQueries
}