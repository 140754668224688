import React from 'react'

import { useApolloClient } from '@apollo/client'
import { Redirect, Route, Switch } from 'react-router-dom'

import Table from '../../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'
import InvoiceQuery from './components/InvoiceValidationQuery'
import { InvoiceTableCreator, InvoiceTableCreatorQueries } from '../../../tableDefinitions/Invoice/Invoice/InvoiceTableCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const Invoice = (props) => {
    document.title = `Invoice - BubbleBPM`
    const client = useApolloClient()

    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(InvoiceTableCreator.getId(), null)
    tableCreatorCreator.setParentProps(props)
    const tableCreator = tableCreatorCreator.getTableCreator('Invoices')

    const path = props.match.url

    return (
        <Switch>
            <Route exact strict path="/:url*" render={props => <Redirect to={`${props.location.pathname}/`}/>} />
            <Route  exact
                    path={path}
                    render={() => <Table
                                    filters={{first: 20}}
                                    TableCreator={tableCreator}
                                    stickyHeader
                                />
                            }
            />
            <Route path={path + "/validate/:id/"}
                    component={({match}) =>  <InvoiceQuery
                                                client={client}
                                                match={match}
                                                QUERY={InvoiceTableCreatorQueries.unitQuery}
                                                TableCreator={tableCreator}
                                            />
                            }
            />
        </Switch>
    )
}

export default Invoice