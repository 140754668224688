import React from 'react'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enLocale from 'date-fns/locale/en-US'
import ptLocale from 'date-fns/locale/pt-BR'
import esLocale from 'date-fns/locale/es'
import DateFnsUtils from '@date-io/date-fns'

import { useDispatch } from 'react-redux'
import { 
    setCurrentUser,
    setUserCustomConfiguration,
    setAllCurrencies,
    setAllLanguages,
    setFirstPublishedPeriod,
    setLastPublishedPeriod,
    setFirstPeriodForProcessedInvoices,
    setLatestPeriodForProcessedInvoices,
} from '../../redux/reducers/BPMReducer'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { gql, useApolloClient, useQuery } from '@apollo/client'

import { ShowLoading } from '../../components/Loading/Loading'

const localeMap = {
    en: enLocale,
    pt: ptLocale,
    es: esLocale
}

const INITIAL_QUERIES = gql`
    query {
        userLanguage {
            language {
                codename
            }
            definitions
        }
        currentUser {
            permissions
            isSuperuser
            isStaff
            role
            id
            email
            firstName
            lastName
            middleName
            title
            language {
                id
                codename
                name
            }
            currency {
                code
                name
                id
                symbol
            }
            manager {
                id
                firstName
                lastName
            }
            company {
                id
                name
                softwareVersion
                softwareChangelog
            }
            hasMultipleCompanies
            membershipSet {
                company {
                    id
                    name
                }
            }
            customConfiguration
            lastPasswordChange
            mustChangePassword
            passwordExpirationDate
            hasSocialOrSsoAuth
        }
        allCurrency {
            edges {
                node {
                    code
                    name
                    id
                    symbol
                }
            }
        }
        allLanguage {
            edges {
                node {
                    codename
                    name
                    id
                }
            }
        }
        firstPublishedPeriod {
            period {
                startDate
            }
        }
        lastPublishedPeriod {
            period {
                startDate
            }
        }
        firstPeriodForProcessedInvoices{
            startDate
        }
        latestPeriodForProcessedInvoices{
            startDate
        }
    }
`

const REGISTER_MISSING_TRANSLATION_QUERY = gql`
    mutation($keys: [String]!, $language: String!, $namespace: String!) {
        registerMissingTranslation(keys: $keys, language: $language, namespace: $namespace) {
            success
        }
    }
`

class PostMissingKeysBackend {
    type = '3rdParty'

    constructor(client) {
        this.client = client
        this.pending_keys = []
        this.requesting = false
    }

    sendRegisterRequest = () => {
        var pending_keys = this.pending_keys;
        this.pending_keys = []
        
        var binnedKeys = pending_keys.reduce((accumulator, currentValue) => {
            var currentBin = accumulator[[currentValue.namespace, currentValue.language]] || [];
            currentBin.push(currentValue.key);
            accumulator[[currentValue.namespace, currentValue.language]] = currentBin;

            return accumulator;
        }, {})

        var keys = binnedKeys[Object.keys(binnedKeys)[0]]
        var [ns, lang] = Object.keys(binnedKeys)[0].split(",");
        
        this.requesting = true;
        this.client.mutate({
            mutation: REGISTER_MISSING_TRANSLATION_QUERY,
            variables: {
                keys: keys,
                language: lang,
                namespace: ns
            }
        }).then( res => {
            this.requesting = false;
            if (this.pending_keys.length > 0) this.sendRegisterRequest();
        }).catch( err=> {
            console.log("Error registering missing translations", err);
            this.requesting = false;
        })
    }

    registerMissingKey = (language, namespace, key) => {
        this.pending_keys.push(
            {
                key: key,
                namespace: namespace,
                language: language,
            }
        )
        
        if (!this.requesting) this.sendRegisterRequest();
    }

    init = instance => {
        instance.options.parseMissingKeyHandler = (key) => {
            return '*'+key+'*'
        };

        instance.on('missingKey', (langs, ns, key, res) => {
            for(const lang of langs) {
                this.registerMissingKey(lang, ns, key)
            }
        })
    }
}

const Init = (props) => {
    const client = useApolloClient()
    const dispatch = useDispatch();
    
    const {loading, error, data} = useQuery(INITIAL_QUERIES)

    if (loading) return <ShowLoading fullScreen />
    
    if (error) return <p>error</p>

    const resources = {}
    resources[data.userLanguage.language.codename] = JSON.parse(data.userLanguage.definitions)

    dispatch(setCurrentUser(data.currentUser))
    dispatch(setUserCustomConfiguration(JSON.parse(data.currentUser.customConfiguration)))
    dispatch(setAllCurrencies(data.allCurrency.edges))
    dispatch(setAllLanguages(data.allLanguage.edges))
    dispatch(setFirstPublishedPeriod(data.firstPublishedPeriod))
    dispatch(setLastPublishedPeriod(data.lastPublishedPeriod))
    dispatch(setFirstPeriodForProcessedInvoices(data.firstPeriodForProcessedInvoices))
    dispatch(setLatestPeriodForProcessedInvoices(data.latestPeriodForProcessedInvoices))

    i18n
        .use(new PostMissingKeysBackend(client))
        .use(initReactI18next)
        .init({
            resources: resources,
            lng: data.userLanguage.language.codename,
            fallbackLng: [data.userLanguage.language.codename],
            saveMissing: true,
            debug: false,
            ns: 'translations',
            defaultNS: 'translations',
            keySeparator: false,
            interpolation: {
                escapeValue: false,
                formatSeparator: ','
            },
            react: {
                wait: true
            } 
        })
 
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[data.userLanguage.language.codename]}>
            {props.children(data)}
        </MuiPickersUtilsProvider>
    )
}

export { Init, INITIAL_QUERIES }