import { gql } from "@apollo/client"

import * as f from '../../components/BPMTable/BPMInputs'
import { 
    DataMapper,
    DataTransformer,
    NullClickStrategy,
    NumberComponentCreator,
    RankingDisplayStrategy,
    TableFieldDescription,
    GeneralExcelDisplayCreator,
    GeneralChipComponentCreator
} from '../../components/BPMTable/TableMapperCreator'
import {ExportFooterItemCreator} from "../../components/BPMTable/TableFooterItems"
import TableCreatorCreator from "../../components/BPMTable/TableCreatorFactory/TableCreatorCreator"
import tableCreatorCreatorFactoryCreator from "../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator"


const tableQuery = gql`
query RankingBySpend(
    $first:Int,
    $period: String!, 
    $currency: String!,
    $vendors: [String]!,
	$ranking: Int!) {
  	rankingBySpend(
        first:$first,
        period:$period, 
        currency:$currency, 
        vendors:$vendors,
        ranking:$ranking
    ) {
        count
        totalCost
        edges{
            node{
                rowNumber
                cost
                percent
                vendor {
                    id
                    name
                }
                inventory{
                    id
                    serviceNumber
                    devices{
                        device{
                            serialNumber
                            model{
                                name
                            }
                        }
                    }
                    serviceType{
                        name
                    }
                }
                user{
                    id
                    firstName
                    lastName
                    title
                    enterpriseId
                    costCenter{
                        enterpriseId
                    }
                }
            	costCenters{
                    costCenter{
                        enterpriseId
                        displayName
                        name
                    }
                }
            }
        }
	}
}
`

const queries = {tableQuery};

class RankingSpendsTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "RankingSpendsTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries, new NullClickStrategy(), new RankingDisplayStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()]);

        tableCreator.setDescriptions([
            new TableFieldDescription(
                'rowNumber',
                new DataMapper("rowNumber"),
                new f.FilterDescription(new f.NullCreator()),
                {label: '', hideSort: true, draggable: false}
            ),
            new TableFieldDescription(
                'vendor',
                new DataMapper("vendor.name"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Vendor', hideSort: true, draggable: false}
            ),
            new TableFieldDescription(
                'servicenumber',
                new DataMapper("inventory.serviceNumber"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Service Number', hideSort: true, draggable: false}
            ),
            new TableFieldDescription(
                'employeeId',
                new DataMapper("user.enterpriseId"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Employee ID', hideSort: true, draggable: false}
            ),
            new TableFieldDescription(
                'firstName',
                new DataMapper("user.firstName"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'First Name', hideSort: true, draggable: false}
            ),
            new TableFieldDescription(
                'lastName',
                new DataMapper("user.lastName"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Last Name', hideSort: true, draggable: false}
            ),
            new TableFieldDescription(
                'costCenters',
                new DataMapper("costCenters"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: "Cost Center",
                    hideSort: true,
                    draggable: false, 
                    excelDisplay : new GeneralExcelDisplayCreator('Cost Center', [{label: 'Name', path: 'costCenter.displayName'}], 1),
                    componentCreator: new GeneralChipComponentCreator(['costCenter.displayName'], null, 'Not Assigned', false),
                    transform: new DataTransformer(({costCenters}) => costCenters ? costCenters : []),
                }
            ),
            new TableFieldDescription(
                'title',
                new DataMapper("user.title"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Title', hideSort: true, draggable: false}
            ),
            new TableFieldDescription(
                'deviceModel',
                new DataMapper("inventory.devices.device.model.name"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Device Model', hideSort: true, draggable: false}
            ),
            new TableFieldDescription(
                'serviceType',
                new DataMapper('inventory.serviceType.name'),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Service Type', hideSort: true, draggable: false}
            ),
            new TableFieldDescription(
                'cost',
                new DataMapper("cost"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Cost', hideSort: true, draggable: false, componentCreator: new NumberComponentCreator(this.parentProps.symbol), transform: new DataTransformer(({cost}) => Number(cost)) }
            ),
            new TableFieldDescription(
                'percent',
                new DataMapper("percent"),
                new f.FilterDescription(new f.NullCreator()),
                {label: '%', translateLabel: false, hideSort: true, draggable: false, componentCreator: new NumberComponentCreator('', 2, '%'), transform: new DataTransformer(({percent}) => Number(percent)) }
            )
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(RankingSpendsTableCreator)

export default RankingSpendsTableCreator;
