const thousandSeparator = (n, digits=0) => {
    n = Number(n).toFixed(digits)
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const quantityTransformer = (n) => {
    n = Number(n)
    if (n < 999) {
        return n.toFixed(2)
    } else if(n > 999 && n < 99999) {
        return `${String((n / 1000).toFixed(2))}K`
    } else if(n >= 100000 && n <= 999999) {
        return `${String((n / 1000).toFixed(2))}K`
    } else {
        return `${String((n / 1000000).toFixed(2))}M`
    }
}

function NotImplementedError(message) {
    this.name = 'NotImplementedError'
    this.message = message || ''
}

NotImplementedError.prototype = Error.prototype

export {
    thousandSeparator,
    quantityTransformer,
    NotImplementedError
}