import React from 'react'
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import { useTableColumnConfig } from '../../../redux/selectors';

const CustomBodyTableCell = withStyles(theme => ({
    root: {
        padding: "0px 0px 0px 10px",
        overflow: "auto",
    },
}))(TableCell);


const BPMTableCell = (props) => {
    const {
        tableName, data, classes, fieldDescription
    } = props;
    const columnConfig = useTableColumnConfig(tableName, fieldDescription.id)
    const fieldVisibility = fieldDescription.getFieldVisibility(columnConfig)

    return (
        <>
            {fieldVisibility ?
                <CustomBodyTableCell component="td" scope="row" className={classes ? classes.tableCell : null}>
                    {fieldDescription.componentCreator.createWith(data[fieldDescription.id], data)}
                </CustomBodyTableCell>
            : null}
        </>
    )
}

export default BPMTableCell;