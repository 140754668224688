import React, { useState } from 'react'

import { withTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom';

import { useCurrentUser } from '../../redux/selectors';

import ReportsFilter from '../../components/ReportsFilter/ReportsFilter'
import Employees from './Employees'
import CostCenter from './CostCenters'
import { shortMonthNames } from '../../components/DateTools/MonthsNames'
import { useLastPublishedPeriod } from '../../redux/selectors';


function MyTeam(props){
    const lastPublishedPeriod = useLastPublishedPeriod()
    const currentUser = useCurrentUser()

    const [date, setDate] = useState({year: lastPublishedPeriod.year, month: lastPublishedPeriod.month})
    const [currency_symbol, SetSymbol] = useState(currentUser.currency ? currentUser.currency.symbol : null)
    const [currency_id, SetCurrencyId] = useState(currentUser.currency ? currentUser.currency.code : null)

    const location = useLocation();
    const changeTabs = location.pathname == '/myTeam/Employees';

    const handleChangeCurrency = (currencyCode, symbol) =>{
        SetSymbol(symbol)
        SetCurrencyId(currencyCode)
    }
  
    function onChangeDate(year, month) {
        setDate({year: year, month: month})
    }
  
    function generateFilters(){
        return {period:`${date.year}-${date.month}`,currency:currency_id}
    }
  
    const periodTransformer = shortMonthNames(props.t)
    
    const month0 = date.month - 1
    const month1 = (month0 - 1 < 0)? month0 + 11 : month0 -1
    const month2 = (month0 - 2 < 0)? month0 + 10 : month0 -2
    const year0 = date.year
    const year1 = ( month1 > month0) ? year0- 1 : year0
    const year2 = ( month2 > month0) ? year0 - 1 : year0
  
    const period2 = String(periodTransformer[month2]+ "-" + year2)
    const period1 = String(periodTransformer[month1]+ "-" + year1)
    const period0 = String(periodTransformer[month0]+ "-" + year0)
  
    var infoLegend = ""
    
    if (changeTabs) {
        document.title = "My Team Employees - BubbleBPM"
        infoLegend  = props.t(`Quarterly cost of your lines and of the employees who report to you`)
    } else {
        document.title = "My Team Cost Centers - BubbleBPM"
        infoLegend = props.t(`Quarterly cost of your Cost Centers and related `)
    }
  
    const buttonsData = [
        {
            label: props.t("Employees"),
            color: !changeTabs ? "gray":"#55758C",
            link: "/myTeam/Employees",
            variant: changeTabs ? "outlined" :"contained"
        },
        {
            label: props.t("Cost Centers"),
            color: changeTabs ? "gray":"#55758C",
            link: "/myTeam/CostCenters",
            variant: !changeTabs ? "outlined" :"contained"      
        }
    ]

    return (
        <>
            <ReportsFilter 
                onChangeDate={onChangeDate}
                buttons={buttonsData}
                date={date}
                info={{label:infoLegend}}
                onChangeCurrency={handleChangeCurrency}
                currencySelected = {currency_id}
                checkDisabledMonths={true}
            />
            { changeTabs ?
                <Employees 
                    t={props.t}
                    symbol={currency_symbol} 
                    filters={generateFilters()}
                    per1={period1}
                    per2={period2}
                    per={period0}
                />
                :
                <CostCenter
                    t={props.t}
                    symbol={currency_symbol}
                    currencyCode={currency_id}
                    filters={generateFilters()}
                    periods={{period0:year0 + "-" + (month0+1) ,period1:year1 + "-" +  (month1+1),period2:year2 + "-" + (month2+1)}}
                    per1={period1}
                    per2={period2}
                    per={period0}
                />
            }
        </>
    )
}

export default withTranslation("MyTeam")(MyTeam)