/* eslint-disable no-unused-vars */
import React, { Component } from 'react'

import { withTranslation } from 'react-i18next'
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@material-ui/core'
import { withStyles, createStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import TablePopUpPicker from "./TablePopUpPicker"

const PaperError = withStyles(theme => ({
  root: {
    border:"2px solid red"
  },
}))(Paper);


class ModelInput extends Component {
  constructor(props){
    super(props);
    this.state = {
      isOpen : false,
      model:this.props.formState.mappedData[this.props.inputId],
    }
  }

  render() {
    const {required, label, onChangeHandler, t, mappers, formState, classes, id} = this.props;
    const inputLabel = required ? required(t(label)) : t(label);
    const error =  Boolean(formState.errors[id]);
    const {model} = this.state

    const headerStyle = {
      backgroundColor:"#0A1F2E",
    }

    const handleOpen = () => {
      this.setState({isOpen:true})
    }
  
    const onChange = (inputId, model) => {
      this.setState({model:model})
      model ? onChangeHandler(inputId, model.id) : onChangeHandler(inputId, null)
    }

    return (
      
          <div>
              <TableContainer component={error ? PaperError : Paper}>
                <Table aria-label="customized table">
                  <TableHead style={headerStyle}>
                    <TableRow>
                      {mappers.map((item, index)=><TableCell key={"cell__"+index} align="center" style={{color:"white", height:"auto !important"}}>{item.label}</TableCell>)}
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  <TableRow>
                    {mappers.map((item, index) => (
                        <TableCell key={"cell__"+index} style={{maxWidth:250}} align="center">
                          <div style={{display:"inline-block", overflow:"hidden", width:100, maxHeight:20}}>
                            {model ? item.mapper(model) : ""}
                          </div>
                          </TableCell>
                    ))}
                    <TableCell>
                      <IconButton onClick={handleOpen} size="medium" style={{padding: 0}}>
                        <EditIcon />
                    </IconButton>
                    </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePopUpPicker 
              isOpen={this.state.isOpen}
              handleClosePopUp={()=>this.setState({isOpen:false})}
              onChange={onChange}
              {...this.props} />
            </div>

    )
  }
}

export default withTranslation('fieldLabels')(ModelInput);