import React, { Component } from 'react'

import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Mutation } from 'react-apollo'
import { Button, CircularProgress, Typography } from '@material-ui/core'

import { AddBPMApplicationMessages } from '../../../../redux/reducers/BPMReducer'
import { connect } from 'react-redux'

import Section from './Section'

const styles = theme => ({
    input: {
      fontSize: 15,
      textAlign: 'left',
    },
    formControl: {
        margin: 8
    },
    section: {
        margin : '10px 0px'
      },
    sectionContent: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    formContent: {
        padding: '0 30px'
    },
    buttonContainerBottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '5px 60px'
    }
})

class Form extends Component {
    state = {
        formData: {},
        errors: {},
        ...this.props.TableObject.mutationMapper(this.props.data),
    }

    handleSave = (path, mutationRequest) => {
        const errors = this.props.TableObject.errorCheck(this.state.mappedData)
	    this.setState({errors: errors});
	    if (Object.values(errors).length === 0 ) {
            mutationRequest()
                .then(response =>{
                    this.props.TableObject.onFormConfirmedStrategy(this, path, response)
                })
                .catch(error => {
                    console.log(error)
                    this.props.AddBPMApplicationMessages({
                        text: error.message, 
                        variant: 'error',
                    })
                });
        };
    };

    onChangeHandler = (input, value, displayData) => {
        const cleanedValue = (typeof(value) == "object" && value && value.id) ? value.id : value
        this.setState(state =>{
            const displayDataCopy = { ...state.displayData, [input]: displayData };
            const mappedData = { ...state.mappedData, [input]: cleanedValue }
            return ({ mappedData: mappedData, displayData: displayDataCopy })
        })
    };

    cancelHandler = (path) => {
        return this.props.TableObject.onFormCanceledStrategy(this,path)
    }

    render() {
        const { classes, mutationQuery, TableObject, t, path } = this.props;

        const variables = TableObject.prepareData(this.state.mappedData);
            
        const inputs = TableObject.getInputs({
            classes:classes,
            formState:this.state,
            onChangeHandler:this.onChangeHandler
        });
        const sections = TableObject.getSections();

        return(
            <div className={classes.formContent}>
                <Typography variant="h4">{t('formNames:' + TableObject.tableName)}</Typography>
                <div>
                    {sections.map(section => {
                        return (
                            <Section
                                section={section}
                                inputs={_.filter(inputs, section)[0]}
                                classes={classes}
                                key={section}
                            />
                        )
                    })}

                </div>

                <Mutation
                    mutation={mutationQuery}
                    variables={variables}
                    errorPolicy="all"  
                    client={this.props.client}
                >
                    {(mutationRequest, {loading}) => {
                        if (loading) {
                            return (
                                <h2 style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    padding: '5px 5px 0 0'
                                }}>
                                    <CircularProgress className={classes.progress}/>
                                        &nbsp;Loading...
                                </h2>
                            )
                        }
                        return (
                            <div style={{
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'flex-end',
                                padding: '15px 5px 0 0'
                            }}>
                                <Button
                                    color="secondary"
                                    size="small"
                                    variant="contained"
                                    onClick={() => this.cancelHandler(path)}
                                    className={classes.button}
                                >
                                    {t('mutation:Cancel')}
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    className={classes.button}
                                    style={{marginLeft: '10px'}}
                                    onClick={event => this.handleSave(path, mutationRequest)}
                                    >
                                    {t('mutation:Save')}
                                </Button>
                            </div>
                        )
                    }}
                </Mutation>
            </div>
        )
    }
}

Form.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapDispatchToProps = {
    AddBPMApplicationMessages,
};

export default withTranslation()(withStyles(styles)(withRouter(connect(null, mapDispatchToProps)(Form))));
