import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import {
    TableFieldDescription,
    DataMapper,
    DataTransformer,
    NullClickStrategy,
    OnlyFilterActionDisplayStrategy,
    GeneralExcelDisplayCreator,
    GeneralChipComponentCreator,
} from  '../../../components/BPMTable/TableMapperCreator'
import { ExportFooterItemCreator } from '../../../components/BPMTable/TableFooterItems'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query (
            $orderField: String,
            $dateFrom: String,
            $dateUntil: String,
            $glAccounts: String,
            $serviceNumber: String!
        ){
        glaccountsAssociations(
            orderField: $orderField,
            dateFrom: $dateFrom,
            dateUntil: $dateUntil,
            glAccounts: $glAccounts,
            serviceNumber: $serviceNumber
        ) {
            edges {
                node {
                    association {
                        id
                        items {
                            glAccount {
                                id
                                displayName
                                name
                            }
                            percentage
                        }
                        inventory {
                            serviceNumber
                        }
                    }
                    dateFrom
                    dateUntil
                }
            }
            count
        }
    }
`;

const queries = {tableQuery}

class InventoryGlAccountsAssociationTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InventoryGlAccountsAssociationTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries, new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()])

        tableCreator.addDescriptions([
            new TableFieldDescription(
                'dateFrom',
                new DataMapper("dateFrom"),
                new f.FilterDescription(new f.DateFilter("dateFrom")),
                {label: 'From', transform: new DataTransformer(({dateFrom})=> dateFrom && dateFrom !== '1900-01-01' ? dateFrom : null)}
            ),
            new TableFieldDescription(
                'dateUntil',
                new DataMapper("dateUntil"),
                new f.FilterDescription(new f.DateFilter("dateUntil")),
                {label: 'Until', transform: new DataTransformer(({dateUntil})=> dateUntil && dateUntil !== '1900-01-01' ? dateUntil : null)}
            ),
            new TableFieldDescription(
                'glAccounts',
                new DataMapper("association.items"),
                new f.FilterDescription(new f.TextFilter("glAccounts")),
                {
                    label: "Gl Accounts",
                    excelDisplay : new GeneralExcelDisplayCreator('Gl Account', 
                        [{label: 'Name', path: 'glAccount.displayName', defaultValue: 'Not Assigned'}, 
                        {label: '%', path: 'percentage', defaultValue: 100.00}], 3),
                    componentCreator: new GeneralChipComponentCreator(['glAccount.displayName'], null, 'Not Assigned'),
                    transform: new DataTransformer(({items}) => items ? items : []),
                }
            ),
        ])
        
        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InventoryGlAccountsAssociationTableCreator)

export default InventoryGlAccountsAssociationTableCreator;