import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    DataTransformer, 
    HideDeletionDisplayStrategy, 
    StatusComponentCreator, 
    BooleanExcelDisplayCreator,
    TableFieldDescription 
} from '../../../components/BPMTable/TableMapperCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query AllConcepts(
        $orderField: String,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $isTax: Boolean,
        $name: String,
        $technology: String,
        $unit: String,
        $trafficClass:String,
    ) {
        allConcepts( 
            orderField: $orderField,
            first: $first,
            last: $last,
            after: $after,
            before: $before,
            isTax: $isTax,
            name: $name,
            technology: $technology,
            unit: $unit,
            trafficClass:$trafficClass,
        ) {
            edges {
                node {
                    id
                    name
                    isTax
                    trafficClass
                    technology {
                        id
                        name
                    }
                    unit {
                        id
                        name
                    }
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation editConceptMutation($isTax: Boolean, $name: String!, $technology: String, $unit: String,$id: String!,$trafficClass:String) {
        editConcept(isTax: $isTax, name: $name, technology: $technology, unit: $unit, id: $id, trafficClass:$trafficClass) {
            concept {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createConceptMutation($isTax: Boolean, $name: String!, $technology: String, $unit: String, $trafficClass:String) {
        createConcept(isTax: $isTax, name: $name, technology: $technology, unit: $unit, trafficClass:$trafficClass) {
            concept {
                id
            }
        }
    }
`

const deleteMutator = gql`
    mutation removeDeviceMutation($ids: [String!], $allRecords: Boolean, $filters: String) {
        removeDevice(ids: $ids, allRecords: $allRecords, filters: $filters) {
            device {
                id
            }
        }
    }
`

const unitQuery = `
    concept(id: $id) {
        id
        name
        isTax
        trafficClass
        technology {
            id
            name
        }
        unit {
            id
            name
        }
    }
`

const ConceptsQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class ConceptTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "ConceptTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, ConceptsQueries)
        tableCreator.setDisplayStrategy(new HideDeletionDisplayStrategy())
        tableCreator.setInitialSortField('name', 'desc')
        tableCreator.addDescriptions ( [
            new TableFieldDescription(
                'name',
                new DataMapper("name"),
                new f.FilterDescription(new f.TextFilter("name")),
                {label: 'Concept Name'}
            ),
            new TableFieldDescription(
                'technology',
                new DataMapper("technology.name"),
                new f.FilterDescription(new f.TextFilter("technology")),
                {label: 'Technology'}
            ),
            new TableFieldDescription(
                'unit',
                new DataMapper("unit.name"),
                new f.FilterDescription(new f.TextFilter("unit")),
                {label: 'Unit'}
            ),
            new TableFieldDescription(
                'isTax',
                new DataMapper("isTax"),
                new f.FilterDescription(new f.BooleanFilter("isTax", "Yes", "No")),
                {label: 'Is Tax',  componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator("Yes", "No")}
            ),
            new TableFieldDescription(
                'trafficClass',
                new DataMapper("trafficClass"),
                new f.FilterDescription(new f.TraficClassFiler("trafficClass")),
                {label: 'Trafic Class'}
            )
        ])
 
        return tableCreator
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(ConceptTableCreator)

export {
    ConceptTableCreator,
    ConceptsQueries
}