import React from 'react'
import {Slide, Dialog, Typography, Divider} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import BPMTable from '../../../components/BPMTable/BPMTable'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator';
import InvoiceGroupPopUpTableCreator from '../../../tableDefinitions/Invoice/InvoicePayment/InvoiceGroupPopUpTableCreator';
import { useCurrentUser } from '../../../redux/selectors';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function InvoicePaymentGroupPopUp(props) {
    const currentUser = useCurrentUser()
    const {client , openD, close, id, classes} = props

    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(InvoiceGroupPopUpTableCreator.getId(), null)
    tableCreatorCreator.setParentProps({...props, ...{currentUserData: currentUser}})
    const tableCreator = tableCreatorCreator.getTableCreator('Invoice Payment Detail')

    return(
      <Dialog
        open={openD}
        maxWidth={false}
        scroll="body"
        fullWidth
        TransitionComponent={Transition}
        disableEnforceFocus
        classes={{paperFullWidth: classes.dialogWidth}}
        onClose={close}
        id={'pickerDialog'+ id }
      >    
        <div style={{height:400}}>
          <center>
              <Typography variant="h6">{`Invoice Payment Group: ${props.id}`}</Typography>
              <Divider />
          </center>  
              <div style={{paddingTop:35,paddingRight:10,paddingBottom:0,paddingLeft:10}}>
                  <BPMTable  
                    TableCreator={tableCreator}
                  />
              </div>
        </div>
      </Dialog>
    )

}

export default withTranslation("InvoicePaymentGroupPopUp")(InvoicePaymentGroupPopUp)