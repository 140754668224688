/* eslint-disable no-unused-vars */
import CanvasJSReact from "./canvasjs.react";

const bubbleColors = ["#27abe1","#BAD6F7","#01bdc1","#051f34","#154d92","#F2EFD8"]
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
CanvasJSReact.CanvasJS.addColorSet("bubbleColors", bubbleColors)
CanvasJSReact.CanvasJS.addColorSet("bubbleColor",["#27abe1"])
CanvasJSReact.CanvasJS.addColorSet("bubbleSecondaryColor",["#051f34"])

const zeroUsageGraphColors = ["#27abe1", "#051f34"] 
CanvasJSReact.CanvasJS.addColorSet("zeroUsageGraphColors", zeroUsageGraphColors)

export const primaryColor = '#0a1f2e'
export const backgroundColor = '#e8f0fe'
export const disabledColor = '#e0e0e0'


export default bubbleColors