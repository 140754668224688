import React, { useState, useEffect } from 'react'

import { Link, useLocation, useParams } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core'
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@material-ui/icons'

import classes from './Login.module.css'
import { useAuth } from '../../contexts/Auth'
import CustomPrimaryButton from '../../components/CustomPrimaryButton/CustomPrimaryButton'
import { getLanguageCaptions } from '../../utils/publicTranslations'

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1),
            marginRight: '2.5px',
            marginBottom: theme.spacing(1),
            marginLeft: '2.5px'
        }
    }
}))

const LOGIN = gql`
    mutation user_auth($input: ObtainJSONWebTokenInput!) {
        tokenAuth(input: $input) {
            token
        }
    }
`

const Login = () => {
    const { SHOW_RESET_PASSWORD, SHOW_SSO, API_BASE_URL } = window['runtimeConfig']
    const SSO_URL = API_BASE_URL + "/sso/start"

    const [loginCaptions, setLoginCaptions] = useState({});
    const { authenticate } = useAuth()
    const muiClasses = useStyles()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        setLoginCaptions(getLanguageCaptions());
    }, []);


    const handleShowPassword = () => {
        setShowPassword(prevState => !prevState)
    }

    const [login, { loading, error }] = useMutation(LOGIN)
    const { errorMessage } = useParams();

    var consolidatedErrorMessage = error ? error.message : errorMessage ? errorMessage : null;

    const location = useLocation()
    const from = location.state ? location.state.from : '/'

    const handleSubmit = (event) => {
        event.preventDefault()

        login({
            variables: {
                input: {
                    email: email,
                    password: password
                }
            }
        })
            .then(res => {
                const authToken = res.data.tokenAuth.token
                authenticate(authToken, from)
            })
            .catch(err => { })
    }

    return (
        <div className={classes.Container}>
            <div className={classes.OptionsContainer}>
                <form className={[classes.LoginForm, muiClasses.root].join(' ')} onSubmit={handleSubmit}>
                    <div className={classes.Logo}>
                        <img src={'//d20xc7q49jirs4.cloudfront.net/static/img/login.png'} alt='bubble_logo' style={{ width: '280px' }} />
                    </div>
                    <TextField
                        label={loginCaptions.userName}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                        InputLabelProps={{ required: false }}
                        error={Boolean(consolidatedErrorMessage)}
                    />
                    <TextField
                        label={loginCaptions.password}
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                        InputLabelProps={{ required: false }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position='end'>
                                    <IconButton
                                        onClick={handleShowPassword}
                                        edge='end'
                                    >
                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                        error={Boolean(consolidatedErrorMessage)}
                    />
                    <CustomPrimaryButton className={classes.Button} type='submit' label={loginCaptions.login} disabled={loading} />
                    <br/>
                    <p style={{ width: '400px' }}>{loginCaptions.advertisement}</p>
                </form>
                {SHOW_SSO ? <CustomPrimaryButton className={classes.Button} label='SSO' onClick={() => { window.location.href = SSO_URL; }} /> : null}
            </div>

            {SHOW_RESET_PASSWORD ?
                <p className={classes.ForgotPassword}>{loginCaptions.forgot} <Link to='/pr' className={classes.ResetLink}>{loginCaptions.reset}</Link></p>
                :
                <p className={classes.ForgotPassword}>{loginCaptions.havingProblems}</p>
            }
        </div>
    )
}

export default Login