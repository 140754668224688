import React from 'react'

import TableObjectCreator from './TableObjectCreator';
import TableWrapper from './TableWrapper'

function SetupTableData(props) {
    const TableObject = TableObjectCreator(props.TableCreator)
    
    if (props.MassiveUploadTemplateUrlAppend) {
        TableObject.setMassiveUploadTemplateUrlAppend(props.MassiveUploadTemplateUrlAppend);
    }

    return (
        <div key={'div_ab'} style={props.style}>
            <TableWrapper
                TableObject={TableObject} 
                additionalFilters={TableObject.getAdditionalFilters()}
                initialFilters={TableObject.getInitialFilters()}
                client={TableObject.client}
                currentTableCount={TableObject.tableCountSelector}
                currentTableFilters={TableObject.tableFiltersSelector}
                currentTableColumns={TableObject.tableColumnsSelector}
                currentTableSlectAllState={TableObject.tableSlectAllStateSelector}
                currentTableSelectedRows={TableObject.tableSelectedRowsSelector}
                currentTableSelectedRefs={TableObject.tableSelectedRefsSelector}
                stickyHeader
            >
                {props.children}
            </TableWrapper>
        </div>
    )    
}

export default SetupTableData