import React from 'react'


const dateDiff = date => {
    const delta = new Date(Date.now()) - new Date(date).getTime()
    const a_date = new Date(delta)
    const days = Math.floor(delta / (1000 * 60 * 60 * 24))
    const hours = Math.floor((delta / (1000 * 60 * 60)) % 24)
    const minutes = Math.floor((delta / (1000 * 60)) % 60)
    const seconds = a_date.getSeconds()
    const millisecoonds = a_date.getMilliseconds()
    

    const renderDays = days > 0 ? `${days} ${days > 1 ? 'Days' : 'Day'}` : ''
    const renderHours = hours > 0 ? `${hours} ${hours > 1 ? 'Hours' : 'Hour'}` : ''
    const renderMinutes = minutes > 0 ? `${minutes} ${minutes > 1 ? 'Minutes' : 'Minute'}` : ''
    const renderSeconds = seconds > 0 ? `${seconds} ${seconds > 1 ? 'Seconds' : 'Second'}` : ''
    const renderMilliseconds = (minutes == 0 && hours == 0 && days == 0) ? `${millisecoonds} ${millisecoonds > 1 || millisecoonds === 0 ? 'Milliseconds' : 'Millisecond'}` : ''
    
    if (delta) {
        return `${renderDays} ${renderHours} ${renderMinutes} ${renderSeconds} ${renderMilliseconds} ago`
    } else {
        return ' - '
    }
}

const checkProcessDuration = (duration, createdAt, state) => {
    if (state == 'NOT_STARTED') {
        return ""
    } else if (state == 'IN_PROCESS') {
        return dateDiff(createdAt)
    } else {
        return parseDuration(duration)
    }
}
    
const parseDuration = (duration) => {
    const delta = Date.parse(duration)
    const a_date = new Date(delta)
    const days = Math.floor(delta / (1000 * 60 * 60 * 24))
    const hours = Math.floor((delta / (1000 * 60 * 60)) % 24)
    const minutes = Math.floor((delta / (1000 * 60)) % 60)
    const seconds = a_date.getSeconds()
    const millisecoonds = a_date.getMilliseconds()

    const renderDays = days > 0 ? `${days} ${days > 1 ? 'Days' : 'Day'}` : ''
    const renderHours = hours > 0 ? `${hours} ${hours > 1 ? 'Hours' : 'Hour'}` : ''
    const renderMinutes = minutes > 0 ? `${minutes} ${minutes > 1 ? 'Minutes' : 'Minute'}` : ''
    const renderSeconds = seconds > 0 ? `${seconds} ${seconds > 1 ? 'Seconds' : 'Second'}` : ''
    const renderMilliseconds = (minutes == 0 && hours == 0 && days == 0) ? `${millisecoonds} ${millisecoonds > 1 || millisecoonds === 0 ? 'Milliseconds' : 'Millisecond'}` : ''
    
    if (millisecoonds) {
        return `${renderDays} ${renderHours} ${renderMinutes} ${renderSeconds} ${renderMilliseconds}`
    } else {
        return ' - '
    }
}
 
export {
    dateDiff,
    checkProcessDuration,
    parseDuration,
}

