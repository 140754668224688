import { gql } from '@apollo/client'

import * as f from '../../components/BPMTable/BPMInputs'
import { 
    TablePickerDisplayStrategy,
    NumberComponentCreator,
    DataTransformer, 
    TableFieldDescription, 
    DataMapper, 
    NullClickStrategy,
    GeneralChipComponentCreator,
    GeneralExcelDisplayCreator,
} from '../../components/BPMTable/TableMapperCreator'
import TableCreatorCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query ExpenseBySiteItem(
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,
        $period:String!,
        $report:String!,
        $currency:String,
        $serviceNumber:String,
        $serviceType:String,
        $vendors:String,
        $costCenterCodes:String,
        $costCenterNames:String,
    ) {
        expenseBySiteItem(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            period:$period,
            report:$report,
            serviceNumber:$serviceNumber,
            serviceType:$serviceType,
            vendors:$vendors,
            costCenterCodes:$costCenterCodes,
            costCenterNames:$costCenterNames,
        ) {
            edges {
                node {
                    cost(currency:$currency)
                    inventory {
                        serviceNumber
                        serviceType {
                            name
                        }
                    }
                  	costCenters {
                      costCenter {
                            id
                            enterpriseId
                            displayName
                        }
                    }
                  	vendors {
                      vendor {
                        name
                      }
                    }
                  }
            }
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const queries = {tableQuery}

class ExpenseBySitePopupContentTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "ExpenseBySitePopupContentTableCreator"
    }

    getTableCreator = (tableName) => {
        const finalTableName =  `${tableName} ${this.parentProps.period} ${this.parentProps?.rowData?.name? this.parentProps.rowData.name : 'NoName'}`
        const tableCreator =  this.createTableCreator(this.constructor.getId(), finalTableName,  queries, new NullClickStrategy(), new TablePickerDisplayStrategy())
        tableCreator.setDescriptions([
            new TableFieldDescription(
                'vendors',
                new DataMapper("vendors"),
                new f.FilterDescription(new f.TextFilter("vendors")),
                {
                    label: "Vendor Names",
                    excelDisplay : new GeneralExcelDisplayCreator('Vendor', [{label: 'Name', path: 'vendor.name'}], 1),
                    componentCreator: new GeneralChipComponentCreator(['vendor.name'], null, null, false),
                    transform: new DataTransformer(({vendors}) => vendors ? vendors : []),
                }
            ),
            new TableFieldDescription(
                'serviceNumber',
                new DataMapper("inventory.serviceNumber"),
                new f.FilterDescription(new f.TextFilter("serviceNumber")),
                {label: 'Service Number'}
            ),
            new TableFieldDescription(
                'serviceType',
                new DataMapper("inventory.serviceType.name"),
                new f.FilterDescription(new f.TextFilter("serviceType")),
                {label: 'Service Type'}
            ),
            new TableFieldDescription(
                'costCenterCodes',
                new DataMapper("costCenters"),
                new f.FilterDescription(new f.TextFilter("costCenterCodes")),
                {
                    label: "Cost Center Codes",
                    excelDisplay : new GeneralExcelDisplayCreator('Cost Center', 
                        [{label: 'Enterprise ID', path: 'costCenter.enterpriseId'}, 
                         {label: 'Name', path: 'costCenter.displayName'}], 2),
                    componentCreator: new GeneralChipComponentCreator(['costCenter.enterpriseId'], null, null, false),
                    transform: new DataTransformer(({costCenters}) => costCenters ? costCenters : []),
                }
            ),
            new TableFieldDescription(
                'costCenterNames',
                new DataMapper("costCenters"),
                new f.FilterDescription(new f.TextFilter("costCenterNames")),
                {
                    label: "Cost Center Names",
                    componentCreator: new GeneralChipComponentCreator(['costCenter.displayName'], null, null, false),
                    transform: new DataTransformer(({costCenters}) => costCenters ? costCenters : []),
                }
            ),
            new TableFieldDescription(
                'currency',
                new DataMapper("cost"),
                new f.FilterDescription(new f.NullCreator()),
                // eslint-disable-next-line no-empty-pattern
                {label: 'Currency', hideSort: true, transform: new DataTransformer(({}) => this.parentProps.currency)}
            ),
            new TableFieldDescription(
                'cost',
                new DataMapper("cost"),
                new f.FilterDescription(new f.NullCreator()),
                {label: this.parentProps.periodString,componentCreator:new NumberComponentCreator(this.parentProps.symbol), transform: new DataTransformer(({cost}) => Number(cost))}
            )
        ])

        return tableCreator
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(ExpenseBySitePopupContentTableCreator)

export default ExpenseBySitePopupContentTableCreator