import React, { useState } from 'react'

import { withRouter } from "react-router-dom";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    LinearProgress,
    withStyles,
    Typography,
} from "@material-ui/core";

import { useDispatch } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { useAxiosClient } from '../../../../contexts/Axios';
import { useMutation } from '@apollo/client'
import massiveLoadTemplateMutation from './MassiveLoadTemplateMutation';
import { AddBPMApplicationMessages } from '../../../../redux/reducers/BPMReducer';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#a7cbc7',
  },
  barColorPrimary: {
    backgroundColor: '#075e54',
  },
})(LinearProgress);

const tableNameToEndpointMapping = {
    'Users': 'user',
    'Cost Centers': 'costcenter',
    'Devices': 'device',
    'Accounts': 'account',
    'Sites': 'site',
    'Gl Accounts': 'glaccount',
    'Legal Entity': 'legalentitie',
    'Inventory': 'inventory',
    'Concept': 'concept',
    'Destination': 'destination',
    'Concepts': 'concept',
    'Destinations': 'destination',
    'Concept Converters': 'concept_converter',
    'Destination Converters': 'destination_converter',
    'Charge Group Converters': 'charge_group_converter',
    'Invoice': 'invoice',
    'Charge Groups':'charge_group',
    'Charge Group Concept':'charge_group_converter',
    'Invoice Payments': 'invoice_payments',
    'Web Credentials': 'web_credentials'
};


const MassiveUploadDialog = props => {
    const [ file, setFile ] = useState(null);
    const [ isLoading, setLoading ] = useState(false);
    const [ progress, setProgress ] = useState(0);

    const { open, handleClose, tableName, t, urlAppend } = props;
    const tableEndpoint = `${tableNameToEndpointMapping[tableName]}`;
    const { axiosClient } = useAxiosClient()
    const [ runMutation, {loading} ] = useMutation(massiveLoadTemplateMutation)

    const dispatch = useDispatch()

    const onSelectFile = e => {
        setFile(e.target.files[0]);
    };

    const onClickDownloadTemplate = () => {
        setLoading(true)

        runMutation({
            variables: {
                model: tableEndpoint,
                ...((urlAppend !== undefined) ? {invoice_id: urlAppend} : null)
            }
        })
        .then(result => {
            const success = result.data;
            if (success) {
                handleQueryExecuted(true, t("Template generation job has been initiated, click here or go to Jobs->Data Load Templates, and download your Template when task finished"))
            } else {;
                handleQueryExecuted(false, t('An error occurred trying to execute Template generation'))
            }
        })
        .catch(error => {
            handleQueryExecuted(false, JSON.stringify(error))
        })
    };

    const createApplicationMessage = (text, variant, clckRedirect) => {
        dispatch(AddBPMApplicationMessages({
            text: text, 
            duration: 8000,
            variant: variant,
            clckRedirect: clckRedirect,
        }))
    }

    const onClickUpload = () => {
        setLoading(true)
        const config = {
            onUploadProgress: (progressEvent) => {
                setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }};
        const data = new FormData();
        data.append('file', file);
        axiosClient.post(
            `/massive_uploads/upload/${tableEndpoint}`,
            data,
            config
        ).then(
            response => {
                createApplicationMessage(t('massiveUpload:File Uploaded. Redirecting...'), 'success')
                setTimeout(() => {
                    handleClose();
                    props.history.push('/massiveUploadControl/');
                }, 4000);
            }
        ).catch(
            error => {
                setLoading(false)
                setProgress(0)
                createApplicationMessage(t('massiveUpload:File Upload Failed'), 'error')
            }
        )
    };

    const handleQueryExecuted  = (success, message) => {
        setLoading(false);
        handleClose();
        createApplicationMessage(message, success ? 'success' : 'error', '/dataLoadTemplates')
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogTitle id="alert-dialog-title" >
                    {`${t('massiveUpload:Massive Upload for')} ${t('tableNames:' + tableName)}`}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                    {t('massiveUpload:Upload File')}
                    <input type="file" name="file" onChange={onSelectFile}/>
                    </DialogContentText>
                    <div style={{height:30}}>
                    {
                    isLoading &&
                        <div style={{paddingTop: 15}}>
                        {
                            progress !== 0 ?
                            <div>
                                <ColorLinearProgress style={{marginBottom:5}} variant="determinate" value={progress} />
                                <Typography style={{textAlign:'center'}}>
                                    {`${progress}%`}
                                </Typography>
                            </div>
                            :
                                <ColorLinearProgress style={{marginBottom:5}} variant="query" />
                        }
                        </div>
                    }
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button
                        key="dab_01"
                        onClick={onClickDownloadTemplate}
                        disabled={isLoading}
                        variant="contained"
                        color="secondary"
                        autoFocus
                    >
                        {t('massiveUpload:Download template')}
                    </Button>

                    <Button
                        key="dab_02"
                        onClick={handleClose}
                        variant="contained"
                    >
                        {t('massiveUpload:Cancel')}
                    </Button>

                    <Button
                        key="dab_03"
                        disabled={isLoading || !file}
                        onClick={onClickUpload}
                        variant="contained"
                        color="secondary"
                        autoFocus
                    >
                        {t('massiveUpload:Upload')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default withTranslation()(withRouter(MassiveUploadDialog));
